import { DataTableCol } from "../../../../../components/data-table/types";

export const TransactionsDataGridCols: DataTableCol[] = [
  {
    title: "Date",
    control: "invoice_date",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Sold To",
    control: "sold_to",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Buyer app",
    control: "buyer_app",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Invoice",
    control: "invoice",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Invoice Amount",
    control: "invoice_amount",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Packing Status",
    control: "packing_status",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Delivery Status",
    control: "delivery_status",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Buyer App Payment Status",
    control: "buyer_appPayment_status",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Buyer Payment Details",
    control: "buyer_payment_details",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Seller Payment Status",
    control: "seller_payment_status",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Order Details",
    control: "details",
    sortable: false,
    canShowColumn: true,
  },
];
