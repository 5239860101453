import React, { useState } from 'react'
import { SubscriptionsDataGridCols } from './data-grid-cols';
import { DataTable } from '../../../../components/custom-table';
import { Loader } from '../../../../components/loader';
import { Search } from '../../../../components/search/search';

export const Subscriptions = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });

    const staticRenewalPendingData = {
        total: 10,
        results: [
            {
                bu_name: "Ashiravad pvt ltd",
                amount: "10,000",
                next_payment: "12-09-2024",
                last_paid: "12-08-2024"
            },
            {
                bu_name: "parle-G ltd",
                amount: "20,000",
                next_payment: "10-09-2024",
                last_paid: "10-08-2024"
            },
        ]
    }

    const onActions = (data: any) => { }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
    };

    return (
        <div className='content-bg-color'>
            <div className='primary-color fw-bold mt-3'>Subscriptions</div>
            <div className="col-md-6 px-0 my-3">
                <Search customClass={true} />
            </div>
            <div className='col-12 px-0 my-2'>
                <Loader LoadingReducer={loading} />
                <DataTable tableData={staticRenewalPendingData}
                    TableCols={SubscriptionsDataGridCols}
                    onActions={(e: any) => onActions(e)}
                    onPageChange={(e: any) => onPageChange(e)}
                    onSelectPageChange={(e) => onSelectPage(e)}
                    pageCount={pageCount} />
            </div>
        </div>
    )
}
