import { API } from "./axios.service";

export class LookupService {
  static async getAllFeatureGrups(): Promise<any> {
    return API.get("api/v1/lookup/user-feature-group");
  }

  static async addNewPrevilige(data: any): Promise<any> {
    return API.post("api/v1/lookup/privileges", data);
  }

  static async getPrevilige(): Promise<any> {
    return API.get("api/v1/lookup/privileges");
  }
}
