import { CustomDataTableCol } from "../../../../components/custom-table/types";
import { DataTableCol } from "../../../../components/data-table/types";

export const TransactionsDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Bu Name',
        control: 'brand',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Mobile Number',
        control: 'mobile_number',
        sortable: true,
        canShowColumn: true
    },
   ];