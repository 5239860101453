import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = (props: any) => {
  const chartRef: any = useRef(null);
  let chartInstance: any = null;

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    chartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: props.data?.labels,
        datasets: [{
          label: props.data?.label,
          data: props.data?.barChart_data,
          backgroundColor: props.data?.backgroundColor,
          borderColor: props.data?.borderColor,
          borderWidth: props.data?.borderWidth,
        }],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: props.data?.xAxis,
              font: {
                size: 16,
                weight: 'bold' 
              },
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: props.data?.yAxis,
              font: {
                size: 16,
                weight: 'bold'
              },
            },
          },
        },
      },
    });

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [props.data]); // Added props.data as a dependency

  return <canvas ref={chartRef} />;
};

export default BarChart;
