import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Subscriptions } from '../pages/Dashboard/reports/subscription/subscriptions'
import { RenewalPending } from '../pages/Dashboard/reports/renewal-pending/renewal-pending'

export const ReportsInfo = () => {
    return (
        <Routes>
            <Route path="/" element={<RenewalPending />}></Route>
            <Route path="/renewal-pending" element={<RenewalPending />}></Route>
            <Route path="/subscriptions" element={<Subscriptions />}></Route>
        </Routes>
    )
}
