import { DataTableCol } from "../../../../../components/data-table/types";

export const TransactionsDataGridCols: DataTableCol[] = [
    {
        title: 'Date',
        control: 'date',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Buyer App',
        control: 'buyer_app',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        sortable: true,
        canShowColumn: true
    },
   ];