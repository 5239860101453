import React, { useEffect, useState } from "react";
import { RegistrationDataGridCols } from "./data-grid-cols";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt } from "../../../app/utility/encryption-helper";
import { DataTable } from "../../../components/data-table";
import { Search } from "../../../components/search/search";
import { DateRangeSelector } from "../../../components/date-filter/daterangeselector";

export const RegistrationDetails = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectDate, setSelectData] = useState<any>();
  const [clearDates, setClearDates] = useState(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  // const [registrationData, setRegistrationData] = useState<any>([]);

  useEffect(() => {
    // loadProducts();
  }, []);

  async function loadProducts(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.productApi(
        "findProductList_v2",
        "908b88a1939383024c36570992489ee3be209a4f0a0d11da4068c8c956c8a41b670fb9f5ed0f0e0b5e46ed19d9938c71b84708463c854c487f550bd5bc1c9873fd65b62db239ef326c8ab83bbd42702219f310a53398c8775f1494662b7b8e314b07c646046ab1d52ea37d168a7f1703"
      );
      const registrationData = JSON.parse(dataDecrypt(res.data.data));
      console.log(registrationData, "registrationData");
      // setRegistrationData(registrationData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const registrationData = [
    {
      id: "1",
      name: "Ramana",
      mobile_number: 9652144578,
      date: "10-10-2024",
      no_of_bu: "10",
      status: "Active",
    },
    {
      id: "2",
      name: "Abhi Ram",
      mobile_number: 9652144579,
      date: "12-10-2024",
      no_of_bu: "15",
      desription: "Health Drinks & Supplements Category",
      status: "Active",
    },
    {
      id: "3",
      name: "Omkar",
      mobile_number: 9852144578,
      date: "13-10-2024",
      no_of_bu: "23",
      desription: "Women Care Category",
      status: "Active",
    },
    {
      id: "4",
      name: "Krishna",
      mobile_number: 9552144580,
      date: "15-10-2024",
      no_of_bu: "25",
      desription: "Personal Care Category",
      status: "Active",
    },
    {
      id: "5",
      name: "Nagarjuna",
      mobile_number: 9952244558,
      date: "18-10-2024",
      no_of_bu: "30",
      desription: "Ayurveda Category",
      status: "Active",
    },
  ];

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };
  const getDateRange = (data: any) => {
    setSelectData(data);
  };
  const onClear = () => {
    setClearDates(true);
  };

  return (
    <div className="container-fluid content-bg-color">
      <h4 className="primary-color fw-bold mt-3">Registrations</h4>
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={registrationData}
            TableCols={RegistrationDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
