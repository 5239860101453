import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../../components/breadcum";
import { NavLink } from "react-router-dom";
import { DataTable } from "../../../components/data-table";
import { OrganizationDataGridCols } from "./data-grid-cols";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { ApisService } from "../../../app/core/apis.service";
import { Search } from "../../../components/search/search";

export const OrganizationsList = () => {
  const [loading, setLoading] = useState(false);
  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Organizations",
      value: "Organizations",
      routerLink: "/dashboard/organizations",
    },
  ]);

  const [organizationData, setOrganizationData] = useState<any>([]);

  useEffect(() => {
    loadOrgs();
  }, []);

  async function loadOrgs(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.getOrganizationData("getAll", encrytedData);
      const organizationData = JSON.parse(dataDecrypt(res.data.data));
      if (organizationData) {
        organizationData.results.forEach((element: any) => {
          element.link = `org-details/${element._id}`;
          element.users_count = element?.users?.length;
          element.invoices = "2";
          element.revenue = "10,000";
          element.products = "25";
        });
        console.log("organization data : ", organizationData?.results);
        setOrganizationData(organizationData.results);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  return (
    <div className="container-fluid content-bg-color">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 text-end d-flex justify-content-between pl-8">
              <h4 className="primary-color fw-bold mt-3">Business Units</h4>
              {/* <Breadcrumb breadCrumbList={breadcrumbInfo}></Breadcrumb> */}
            </div>
          </div>
          <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        {/* <div className="col-md-4 my-3">
          <DateRangeSelector 
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div> */}
        <div className="col-md-3 my-3">
            <button className="btn btn-primary">Search</button>&nbsp;
            <button className="btn btn-secondary">Clear</button>
        </div>
      </div>

          <div className="row ">
            <div className="col-12 px-0">
              {loading && (
                <div className="text-center p-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}
              {!loading && (
                <>
                  <DataTable
                    tableData={organizationData}
                    TableCols={OrganizationDataGridCols}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
