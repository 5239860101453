import { CustomDataTableCol } from "../../../components/custom-table/types";
import { DataTableCol } from "../../../components/data-table/types";

export const SubscriptionDataGridCols: DataTableCol[] = [
  {
    title: "Business Unit Name",
    control: "business_name",
    sortable: true,
  },
  {
    title: "Business Head",
    control: "head_of_business",
    sortable: true,
  },
  {
    title: `Phone Number`,
    control: `contact_number`,
    sortable: true,
    // action: 'link',
    // type: 'link',
  },
  {
    title: `Last Paid`,
    control: `last_paid`,
    sortable: true,
    // action: 'link',
    // type: 'link',
  },
  {
    title: `Status`,
    control: `status`,
    sortable: true,
    // action: 'link',
    // type: 'link',
  },
];
