import React, { useEffect, useState } from "react";
import { SubscriptionDataGridCols } from "./data-grid-cols";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt } from "../../../app/utility/encryption-helper";
import { DataTable } from "../../../components/data-table";
import { Search } from "../../../components/search/search";
import Modal from "react-bootstrap/Modal";
import { Button, ModalBody } from "react-bootstrap";
import { DateRangeSelector } from "../../../components/date-filter/daterangeselector";
import { CiExport } from "react-icons/ci";
import moment from "moment";

export const Subscriptions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [show, setShow] = useState(true);
  const [SubscriptionDta, setSubscriptionDta] = useState<any>([]);
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();
  useEffect(() => {
    loadOrgs();
  }, []);

  async function loadOrgs(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.getOrganizationData("getAll", encrytedData);
      const subScriptionData = JSON.parse(dataDecrypt(res.data.data));
      console.log(subScriptionData, "subScriptionData");
      const response = subScriptionData?.results;
      response.forEach((el: any) => {
        el.last_paid = "20-10-2024";
        el.status = "Active";
      });
      setSubscriptionDta(subScriptionData.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {
    console.log("dadadada");

    setShow(true);
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const handleClose = () => {
    setShow(false);
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  return (
    <div className="container-fluid content-bg-color">
      <div className="row align-items">
        <h4 className="primary-color fw-bold mt-3 col-md-6">Subscriptions</h4>
        <div className="col-md-6 text-end">
          {/* <button className="btn btn-primary me-2"><CiExport size={20} className="me-2"/>Export</button> */}
          {/* <button className="btn btn-primary">Export</button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={SubscriptionDta}
            TableCols={SubscriptionDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
      {/* <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>Buyer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="fw-bold">Buyer App </div>
              <div className="fw-bold">Amount </div>
              <div className="fw-bold">Date </div>
              <div className="fw-bold">Transaction Id</div>
            </div>
            <div className="col-6">
              <div>: Paytm</div>
              <div>: 1000</div>
              <div>: 15-09-2024</div>
              <div>: 123456</div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
