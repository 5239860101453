import { CustomCard } from "../../../components/custom-card/custom-card";
import Invoices from "./../../../assets/images/inovoice-three.png";
import Users from "./../../../assets/images/users.svg";
import {
  FaBox,
  FaCartFlatbedSuitcase,
  FaFileInvoice,
  FaSitemap,
  FaUserGroup,
} from "react-icons/fa6";
import { RiNumbersFill } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import BarChart from "../../../components/charts/bar-chart";

export const DashboardInfoPage = () => {
  const invoicesData: any[] = [
    {
      id: "1",
      name: "Total Number of BU'S",
      count: 250,
      icon: <FaSitemap size={45} />,
      backgroundColor: "#387478",
    },
    {
      id: "2",
      name: "Total Number of Invoices",
      count: 320,
      icon: <FaFileInvoice size={45} />,
      backgroundColor: "#FF8B8B",
    },
    {
      id: "3",
      name: "Total Number of Products",
      count: 150,
      icon: <FaBox size={45} />,
      backgroundColor: "#9772FB",
    },
    {
      id: "3",
      name: "Total Number of ONDC Orders",
      count: 98,
      icon: <FaCartFlatbedSuitcase size={45} />,
      backgroundColor: "#FF7272 ",
    },
    {
      id: "3",
      name: "Total Number of Users",
      count: 200,
      icon: <FaUserGroup size={45} />,
      backgroundColor: "#F9975D",
    },
    {
      id: "3",
      name: "Avg Order Values",
      count: "1000",
      icon: <RiNumbersFill size={45} />,
      backgroundColor: "#F075AA",
    },
    {
      id: "3",
      name: "Total Monthly Transactions",
      count: "10 Lakhs",
      icon: <GrTransaction size={45} />,
      backgroundColor: "#A28B55",
    },
  ];

  const barChartDataData = {
    labels: [
      "SwiftSales Hub",
      "PeakSell Solutions",
      "Veloce Commerce",
      "NextGen Marketplace",
      "PrimeTrade Ventures",
    ],
    label: "Monthly 1 sep - 30 sep",
    barChart_data: ["100", "250", "380", "280", "500"],
    backgroundColor: "#074173",
    borderColor: "white",
    borderWidth: 1,
    xAxis: "Company",
    yAxis: "No of Orders",
  };

  const CategoriesChartDataData = {
    labels: [
      "Noise Electronics",
      "Pick & Buy store",
      "Sree Ram cotton",
      "Sonia electronics",
      "A2Square Enterprises",
    ],
    label: "Monthly 1 sep - 30 sep",
    barChart_data: ["100", "250", "380", "280", "500"],
    backgroundColor: "#804674",
    borderColor: "white",
    borderWidth: 1,
    xAxis: "Categories",
    yAxis: "No of Orders",
  };

  const ChartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    label: "Orders",
    barChart_data: ["10", "25", "18", "25", "58", "40", "90"],
    backgroundColor: "#04364A",
    borderColor: "white",
    borderWidth: 1,
    xAxis: "Days",
    yAxis: "No of Orders",
  };

  return (
    <div className="container-fluid content-bg-color">
      <h4>Dashboard</h4>
      <hr className="mt-0" />
      <div className="row  pb-2">
        <CustomCard
          contentData={invoicesData}
          customClass={true}
          blogsContent={true}
        />
      </div>

      <div className="row">
        <div className="col-md-6">
          <h4 className="mb-3">Orders By Company</h4>
          <BarChart data={barChartDataData} />
        </div>
        <div className="col-md-6">
          <h4 className="mb-3">Orders By Category</h4>
          <BarChart data={CategoriesChartDataData} />
        </div>
      </div>
      <div className="col-md-6">
        <h4 className="mt-3">Daily Orders</h4>
        <BarChart data={ChartData} />
      </div>
    </div>
  );
};
