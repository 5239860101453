import React, { useEffect, useState } from "react";
import { dataDecrypt } from "../../../../app/utility/encryption-helper";
import { ApisService } from "../../../../app/core/apis.service";
import { Search } from "../../../../components/search/search";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { DateRangeSelector } from "../../../../components/date-filter/daterangeselector";
import { DataTable } from "../../../../components/data-table";

export const Transactions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  // const [transactionsData, setTransactionsData] = useState<any>([]);
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();

  useEffect(() => {
    loadTransactions();
  }, []);

  async function loadTransactions(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.productApi(
        "findProductList_v2",
        "908b88a1939383024c36570992489ee3be209a4f0a0d11da4068c8c956c8a41b670fb9f5ed0f0e0b5e46ed19d9938c71b84708463c854c487f550bd5bc1c9873fd65b62db239ef326c8ab83bbd42702219f310a53398c8775f1494662b7b8e314b07c646046ab1d52ea37d168a7f1703"
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      // setTransactionsData(transactionsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const transactionsData = [
      {
        id: "1",
        name: "Sanjev Kumar",
        brand: "Sam Santosa",
        mobile_number: "9099899545",
      },
      {
        id: "2",
        name: "Nageswarao",
        brand: "James Shop",
        mobile_number: "9099899895",
      },
      {
        id: "3",
        name: "Uday",
        brand: "Uday Store",
        mobile_number: "9099895745",
      },
    ];

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        {/* <div className="col-md-4 my-3">
          <DateRangeSelector 
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div> */}
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={transactionsData}
            TableCols={TransactionsDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e: any) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
