import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { InvitationPage } from '../pages/Dashboard/invitation/invitation'
import { MenuSystem } from '../pages/Dashboard/menu-system/menu-system'
import { OrganizationsList } from '../pages/Dashboard/organization/list'
import { OrgTabInfo } from '../pages/Dashboard/organization/org-tab-info'
import { ReportsInfo } from './reports'
import { DashboardInfoPage } from '../pages/Dashboard/dashboard/dashboard'
import { RegistrationDetails } from '../pages/Dashboard/registrations/list'
import { BusinessUnits } from '../pages/Dashboard/business-units/list'
import { SettlementDetails } from '../pages/Dashboard/settlements/list'
import { Subscriptions } from '../pages/Dashboard/subscriptions/list'
import { Prevliges } from '../pages/Dashboard/previliges/previliges'


export const DashboardRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<DashboardInfoPage />}></Route>
        <Route path="/info" element={<DashboardInfoPage />}></Route>
        <Route path='/registration' element={<RegistrationDetails/>}></Route>
        <Route path="/business-units" element={<BusinessUnits />}></Route>
        <Route path="/organizations" element={<OrganizationsList />}></Route>
        <Route path="/organizations/org-details/:org" element={<OrgTabInfo />}></Route>
        <Route path="/invitation" element={<InvitationPage />}></Route>
        <Route path="/settlements" element={<SettlementDetails/>}></Route>
        <Route path="/menu-system" element={<MenuSystem type='system' />}></Route>
        <Route path="/reports" element={<ReportsInfo />}></Route>
        <Route path='/subscriptions' element={<Subscriptions/>}></Route>
        <Route path='/previliges' element={<Prevliges/>}></Route>
      </Routes>
    </div>
  )
}
