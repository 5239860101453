import React, { useEffect, useRef, useState } from "react";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt } from "../../../app/utility/encryption-helper";
import Select from "react-select";
import { API } from "../../../app/core/axios.service";
import { LookupService } from "../../../app/core/lookup.service";
import { useNavigate } from "react-router-dom";

interface Props {
  onClose: () => void;
}
interface FeatureData {
  value: string;
  label: string;
}

export const AddPrevilige = (props: Props) => {
  const previligeName = useRef<HTMLInputElement>(null);
  const previligeCode = useRef<HTMLInputElement>(null);
  const featureGroup = useRef<any>(null);
  const featureCodes = useRef<any>(null);
  const navigate = useNavigate();
  const [availableFeatures, setAvailableFeatures] = useState<FeatureData[]>([]);
  const [availableFeatureGroups, setAvailableFeatureGroups] = useState([]);

  useEffect(() => {
    loadMenuSystem();
    loadFeatureGroups();
  }, []);

  const onSubmit = async () => {
    console.log(
      "on submit",
      previligeName.current?.value,
      previligeCode.current?.value,
      featureCodes.current?.getValue(),
      featureGroup.current?.value
    );
    const data = {
      name: previligeName.current?.value,
      code: previligeCode.current?.value,
      features: featureCodes.current?.getValue().map((el: any) => el.value),
      feature_group: featureGroup.current?.value,
    };
    try {
      const res = await LookupService.addNewPrevilige(data);
      if (res.status === "success") {
        props.onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadMenuSystem = async () => {
    const res = await ApisService.getOrganizationData("getOrgMenuSystem", "");

    if (res.data && res.data.data) {
      let detectedFeatures = [];
      const menuData = JSON.parse(dataDecrypt(res.data.data));
      for (let index = 0; index < menuData.menus.length; index++) {
        const element = menuData.menus[index];
        detectedFeatures.push(element.code);
        if (element.children) {
          loadChildrenFeatures(element.children, detectedFeatures);
        }
      }
      setAvailableFeatures(
        detectedFeatures.map((el) => {
          return {
            value: el,
            label: el,
          };
        })
      );
    }
  };

  const loadChildrenFeatures = (
    menuData: any[],
    detectedFeatures: string[]
  ) => {
    for (let index = 0; index < menuData.length; index++) {
      const element = menuData[index];
      detectedFeatures.push(element.code);
      if (element.children) {
        loadChildrenFeatures(element.children, detectedFeatures);
      }
    }
  };

  const loadFeatureGroups = async () => {
    const featureGroups = await LookupService.getAllFeatureGrups();
    setAvailableFeatureGroups(featureGroups);
  };

  return (
    <div className="row">
      <div className="col-12 text-end d-flex justify-content-between pl-8">
        <h4 className="primary-color fw-bold mt-3">Add Previlige</h4>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="previlige_name">Previlige Name</label>
          <input
            ref={previligeName}
            type="text"
            className="form-control"
            id="previlige_name"
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="previlige_code">Previlige Code</label>
          <input
            ref={previligeCode}
            type="text"
            className="form-control"
            id="previlige_code"
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="previlige_code">Previlige Group</label>
          <select ref={featureGroup} className="form-control">
            {availableFeatureGroups.map((el: any, index: any) => (
              <option value={el.code} key={index}>
                {el.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label>Features</label>
          <Select
            ref={featureCodes}
            options={availableFeatures}
            isMulti={true}
          />
        </div>
      </div>
      <div className="col-md-12">
        <button className="btn btn-secondary" onClick={props.onClose}>
          Cancel
        </button>
        <button className="btn btn-primary ms-3" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};
