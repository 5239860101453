import React, { useState } from 'react'

interface Props {
  customClass?: boolean;
}

export const Search = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchInput = (e: any) => {
    const search = e.target.value;
    setSearchTerm(search)
  }

  const onClickSearch = () => {
    console.log("saerch text :", searchTerm);
  }

  return (
    <div className='row'>
      <div className={`position-relative ${props?.customClass ? "" : "ms-2 ps-3"}`}>
        <input type="text" className='form-control search py-2' placeholder='search' onChange={handleSearchInput}></input>
        <span className='search-position' onClick={onClickSearch}><i className="bi bi-search"></i></span>
      </div>
    </div>
  )
}
