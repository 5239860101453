import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrgUsers } from "../../../../components/organization-tabs/org-users/org-users";
import { OrgDetails } from "../org-details";
import { ApisService } from "../../../../app/core/apis.service";
import { dataDecrypt } from "../../../../app/utility/encryption-helper";
import { Breadcrumb } from "../../../../components/breadcum";
import { InvoicesList } from "./invoices/list";
import { OrdersList } from "./orders/list";

export const OndcOrdersInfo = () => {
  const tabs = [
    {
      id: "1",
      title: "Orders",
      content: <OrdersList />,
    },
    {
      id: "2",
      title: "Invoices",
      content: <InvoicesList />,
    }
  ];
  const params = useParams();
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [loading, setLoading] = useState(false);
  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Business Units",
      value: "Business Units",
      routerLink: "/dashboard/organizations",
      isActive: false,
    },
    {
      label: "Organization",
      value: "",
      routerLink: `/dashboard/organizations/org-detail/${params?.org}`,
      isActive: true,
    },
  ]);


  useEffect(() => {
    loadOrgs();
  }, []);

  async function loadOrgs(): Promise<void> {
    setLoading(true);
    try {
      let encryptedData: string | undefined = params?.org || "";
      const res = await ApisService.getOrganizationData(
        "getAll",
        encryptedData
      );
      const organizationData = JSON.parse(dataDecrypt(res.data.data));
      const filteredOrgData = organizationData?.results?.filter(
        (el: any) => el?.id === params?.org
      );
      const data = breadcrumbInfo;
      data[1].value = filteredOrgData[0]?.business_name;
      setBreadcrumbInfo([...data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }
  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className="container-fluid pt-5">
      {/* <div className="">
        <Breadcrumb breadCrumbList={breadcrumbInfo}></Breadcrumb>
      </div> */}
      {/* <div className="row  pb-2">
                <CustomCard contentData={invoicesData} customClass={true} blogsContent={true} />
            </div> */}
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
              id={`${tab.id}-tab`}
              onClick={() => handleTabClick(tab.id)}
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls={tab.id}
              aria-selected={activeTab === tab.id}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${
              activeTab === tab.id ? "show active" : ""
            }`}
            id={tab.id}
            role="tabpanel"
            a-labelledby={`${tab.id}-tab`}
            key={tab.id}
          >
            {activeTab === tab.id && tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};
