import { CustomDataTableCol } from "../../custom-table/types";

export const OrganizationProductsDataGridCols: CustomDataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Brand',
        control: 'brand',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Quantity',
        control: 'quantity',
        sortable: true,
        canShowColumn: true
    },
   ];