import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../components/data-table'
import { InvitationDataGridCols } from './data-grid-cols'
import { Breadcrumb } from '../../../components/breadcum'
import { NavLink } from 'react-router-dom'
import { Search } from '../../../components/search/search'
import { FilterSearch } from '../../../components/search/filter-search'
import { ApisService } from '../../../app/core/apis.service'
import { dataDecrypt, encrypt } from '../../../app/utility/encryption-helper'
export const InvitationPage = () => {
    const [loading, setLoading] = useState(false);
    // const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    //     {
    //         label: "Invitation",
    //         value: "Invitation",
    //         routerLink: "/dashboard/invitation",
    //         isActive: false,
    //     },
    //     {
    //         label: "Company Name",
    //         value: "",
    //         routerLink: "",
    //         isActive: false,
    //     },
    // ]);



    const tableData: any = [
        {
            company_name: "test1",
            email_id: "test1@gmail.com",
            contact: '9534934349'
        },
        {
            company_name: "test2",
            email_id: "test2@gmail.com",
            contact: '9534934349'
        },
        {
            company_name: "test3",
            email_id: "test3@gmail.com",
            contact: '9534934449'
        },
        ,
        {
            company_name: "test4",
            email_id: "test4@gmail.com",
            contact: '9534964349'
        }
        ,
        {
            company_name: "test5",
            email_id: "test5@gmail.com",
            contact: '95349343469'
        }
    ];

    useEffect(() => {
        loadInvitations();
    }, []);

    async function loadInvitations(): Promise<void> {
        setLoading(true);
        try {
            let encrytedData = "";
            const res = await ApisService.getInvitationData('getAll', encrytedData)
            const invitationData = JSON.parse(dataDecrypt(res.data.data));
            console.log(invitationData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error in transaction:', error);
        }
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-12 text-end d-flex justify-content-between pl-8'>
                            <div className='primary-color fw-bold mt-3'>Invitation</div>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-between my-1">
                        <div className="col-md-6 px-0">
                            <Search
                            />
                        </div>
                        <div className="mb-3 mt-1  col-md-6 text-end">
                            <NavLink to='/dashboard/invitation/form'>
                                <button className='custom-bg-btn text-white border-0 px-4 py-1 me-2'>Create Invitation</button>
                            </NavLink>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 px-0'>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading &&
                                <>
                                    <DataTable tableData={tableData} TableCols={InvitationDataGridCols} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
