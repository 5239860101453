export const xls_keys = [
    'Name', 'Brand', 'Description', 'Product Segment', 'Product Category', 'Product Sub-Category',
    'In Stock', 'For Sale', 'Feature Name', 'Feature Details', 'Quantity', 'Quantity Unit (KG, Unit..)',
    'Batch Number', 'MRP', 'Distributor Sales price', 'Net Rate (Our Price)', 'Currency', 'Manufactured Date',
    'Expiration Date', 'Manufacturer Mobile Number', 'Release Date', 'Manufacturer Name', 'Plot No', 'Building',
    'Street Name', 'Manufacture Country', 'Manufacture State', 'Manufacture District', 'Manufacture City',
    'Manufacture Pin Code', 'Item Height (m/cm/mm)', 'Item Width(m/cm/mm)', 'Item Depth(m/cm/mm)', 'Item Volume(l/ml)',
    'Item Weight(kg/g/mg)', 'Tax Type', 'Tax HSN Code', 'Tax Rate (%)', 'Offer Name', 'Offer Description',
    'Offer Active (true/false)', 'Offer Discount  In Percentage (yes/no)', 'Offer Discount In Amount (yes/no)',
    'Offer Value', 'Minimum Order', 'Maximum Order', 'Offer Start Date', 'Offer End Date', 'Inventory Location Name',
    'Inventory Batch Number', 'Inventory Quantity'
];

const trueFalseOptions: any = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
const unitOptions: any = [
  { label: "unit", value: "unit" },
  { label: "box", value: "box" },
  { label: "can", value: "can" },
  { label: "bottle", value: "bottle" },
  { label: "strip", value: "strip" },
  { label: "lb", value: "lb" },
  { label: "oz", value: "oz" },
  { label: "kg", value: "kg" },
  { label: "grams", value: "grams" },
  { label: "litre", value: "litre" },
  { label: "milli-litre", value: "milli-litre" },
  { label: "tablet", value: "tablet" },
  { label: "tray", value: "tray" },
  { label: "pallet", value: "pallet" },
  { label: "dozen", value: "dozen" },
  { label: "pint", value: "pint" },
  { label: "gallon", value: "gallon" },
];
export const ProductHeadings = [
  { heading: 'Product Name', type: 'text', control: 'name' },
  { heading: 'Brand', type: 'text', control: 'brand' },
  { heading: 'Description', type: 'text', control: 'description' },
  // { heading: 'Product Sector', type: 'select', control: 'product_sector' },
  // { heading: 'Product Segment', type: 'onfly-select', control: 'product_segment', onfly_options: { key: 'segments', dependency: 'product_sector', mappingKey: 'product_segment' } },
  // { heading: 'Product Category', type: 'onfly-select', control: 'product_category', onfly_options: { key: 'categories', dependency: 'product_segment', mappingKey: 'product_category' } },
  // { heading: 'Product Sub-Category', type: 'onfly-select', control: 'product_sub_category', onfly_options: { key: 'subCategories', dependency: 'product_category', mappingKey: 'product_sub_category' } },
  { heading: 'In Stock', type: 'select', control: 'in_stock', options: trueFalseOptions },
  { heading: 'For Sale', type: 'select', control: 'for_sale', options: trueFalseOptions },
  { heading: 'Quantity', type: 'number', control: 'quantity' },
  { heading: 'Quantity Unit (KG, Unit..)', type: 'select', control: 'quantity_in', options: unitOptions },
  { heading: 'Batch Number', type: 'text', control: 'batch_number' },
  { heading: 'Distributor Price', type: 'number', control: 'batch_price' },
  { heading: 'Net Rate (Our Price)', type: 'number', control: 'batch_net_rate' },
  { heading: 'MRP', type: 'number', control: 'batch_mrp' },
  { heading: 'Currency', type: 'select', control: 'batch_currency_code', options: [{ label: 'INR', value: 'INR' }] },
  { heading: 'Manufactured Date', type: 'date', control: 'manufacture_date_time' },
  { heading: 'Expiration Date', type: 'date', control: 'expiry_date' },
  { heading: 'Manufacturer Mobile Number', type: 'text', control: 'manufacture_mobile' },
  { heading: 'Manufacturer Name', type: 'text', control: 'manufacture_name' },
  { heading: 'Manufacturer Plot No', type: 'text', control: 'manufacture_plot_no' },
  { heading: 'Manufacturer Building', type: 'text', control: 'manufacture_building' },
  { heading: 'Manufacturer Street Name', type: 'text', control: 'manufacture_street' },
  { heading: 'Manufacturer Country', type: 'text', control: 'manufacture_country' },
  { heading: 'Manufacturer State', type: 'text', control: 'manufacture_state' },
  { heading: 'Manufacturer City', type: 'text', control: 'manufacture_city' },
  { heading: 'Manufacturer Pin Code', type: 'text', control: 'manufacture_pin_code' },
  { heading: 'Item Height (meter)', type: 'number', control: 'packing_height', postfix: ' meter' },
  { heading: 'Item Width (meter)', type: 'number', control: 'packing_width', postfix: ' meter' },
  { heading: 'Item Depth (meter)', type: 'number', control: 'packing_depth', postfix: ' meter' },
  { heading: 'Item Volume(ltr)', type: 'number', control: 'packing_volume', postfix: ' ltr' },
  { heading: 'Item Weight(grams)', type: 'number', control: 'packing_weight', postfix: ' gr' },
  { heading: 'Tax Type', type: 'select', control: 'tax_type', options: [{ label: 'GST', value: 'GST' }, { label: 'VAT', value: 'VAT' }] },
  { heading: 'Tax HSN Code', type: 'text', control: 'tax_hsn_code' },
  { heading: 'Tax Rate (%)', type: 'number', control: 'tax_rate' }
];
export const KeyConversion: any = {
  manufacture_details: {
    type: 'obj',
    keys: [
      {
        key: 'manufacture_name',
        dbKey: 'name'
      },
      {
        key: 'manufacture_mobile',
        dbKey: 'mobile_number'
      },
      {
        key: 'manufacture_building',
        dbKey: 'building_name'
      },
      {
        key: 'manufacture_plot_no',
        dbKey: 'plot_no'
      },
      {
        key: 'manufacture_street',
        dbKey: 'street'
      },
      {
        key: 'manufacture_country',
        dbKey: 'country'
      },
      {
        key: 'manufacture_state',
        dbKey: 'state'
      },
      {
        key: 'manufacture_city',
        dbKey: 'city'
      },
      {
        key: 'manufacture_pin_code',
        dbKey: 'pin'
      }
    ]
  },
  shipping_details: {
    type: 'obj',
    keys: [
      {
        key: 'packing_height',
        dbKey: 'height', postfix: ' meter'
      },
      {
        key: 'packing_width',
        dbKey: 'width', postfix: ' meter'
      },
      {
        key: 'packing_depth',
        dbKey: 'depth', postfix: ' meter'
      },
      {
        key: 'packing_volume',
        dbKey: 'volume', postfix: ' ltr'
      },
      {
        key: 'packing_weight',
        dbKey: 'weight', postfix: ' gr'
      }
    ]
  },
  tax: {
    type: 'obj',
    keys: [
      {
        key: 'tax_type',
        dbKey: 'type'
      },
      {
        key: 'tax_hsn_code',
        dbKey: 'hsn_code'
      },
      {
        key: 'tax_rate',
        dbKey: 'value'
      }
    ]
  },
  batch_pricing: {
    type: 'array',
    keys: [
      {
        key: 'quantity',
        dbKey: 'quantity'
      },
      {
        key: 'quantity_in',
        dbKey: 'quantity_in'
      },
      {
        key: 'batch_price',
        dbKey: 'price'
      },
      {
        key: 'batch_number',
        dbKey: 'batch_number'
      },
      {
        key: 'batch_net_rate',
        dbKey: 'net_rate'
      },
      {
        key: 'batch_mrp',
        dbKey: 'mrp'
      },
      {
        key: 'batch_currency_code',
        dbKey: 'currency_code'
      }
    ]
  }
};
