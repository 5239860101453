import { CustomDataTableCol } from "../../../components/custom-table/types";
import { DataTableCol } from "../../../components/data-table/types";

export const SettlementDataGridCols: DataTableCol[] = [
  {
    title: "Seller Name",
    control: "seller_name",
    sortable: true,
  },
  {
    title: "Invoice Number",
    control: "invoice",
    sortable: true,
  },
  {
    title: "Buyer App",
    control: "buyer_app",
    sortable: true,
  },
  {
    title: `Amount`,
    control: `invoice_amount`,
    sortable: true,
    // action: 'link',
    // type: 'link',
  },
  {
    title: `Buyer Payment Date`,
    control: `buyer_payment_date`,
    sortable: true,
    // action: 'link',
    // type: 'link',
  },
  {
    title: `Buyer App Payment Status`,
    control: `buyer_payment_status`,
    sortable: true,
    // action: 'link',
    // type: 'link',
  },
  {
    title: "Buyer Payment Details",
    control: "buyer_payment_details",
    sortable: true,
    type: "link",
    action: "link",
  },
  {
    title: "Seller Payment Status",
    control: "seller_payment_status",
    sortable: true,
    type: "link",
    action: "link",
  },
];
