import React, { useEffect, useState } from "react";
import { SettlementDataGridCols } from "./data-grid-cols";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt } from "../../../app/utility/encryption-helper";
import { DataTable } from "../../../components/data-table";
import { Search } from "../../../components/search/search";
import Modal from "react-bootstrap/Modal";
import { Button, ModalBody } from "react-bootstrap";
import { DateRangeSelector } from "../../../components/date-filter/daterangeselector";
import { CiExport } from "react-icons/ci";
import moment from "moment";

export const SettlementDetails = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [show, setShow] = useState(true);
  const [settlementData, setSettlementData] = useState<any>([]);
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();
  useEffect(() => {
    loadProducts();
  }, []);

  async function loadProducts(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.transactionApi(
        "findByTransactionBuId",
        "b4472d7db1311dbeb910c70ba02fbaae7386ebf0224fd956f0a1127bc958059f5fe897c87234b77ced62414746d66650b88fa471d55dbf12786f61a462efd175d34f569ff5b770ef86d87cdd10f11ede3986c6c36cfb3b407dfc80d9d2e978dfa363d091b977687958d71c038581332f71c81ca0dc57f9675ab80f5d716e0cfebf6b79e3fd1860e8d923bc12aa714085dece3962871cd6058e51ac42f8ce58fcd6bc779e51c399425d2d9ef751a7ed60"
      );
      const settlementData = JSON.parse(dataDecrypt(res.data.data));
      console.log(settlementData, "settlementData");
      const response = settlementData?.results;
      console.log(response,"response");
      response.forEach((element: any) => {
        element.buyer_app = element?.ondc?.buyer_app;
        element.invoice = element?.invoice_details ? element?.invoice_details[0]?.bill_no : "";
        element.invoice_amount = element?.invoice_details ? element?.invoice_details[0]?.invoice_amount: "";
        element.seller_payment_status=<button className="rounded btn btn-primary" style={{fontSize:'14px'}}>Make Payment</button>
      });
      const orderData = response.filter((order: any) => order.type === "ONDC");
      setSettlementData(orderData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  // const settlementData = [
  //   {
  //     id: "1",
  //     seller_name: "sanjeev kumar",
  //     invoice_number: "101",
  //     buyer_app: "Paytm",
  //     amount: "1000",
  //     buyer_payment_date: "10-09-2024",
  //     buyer_payment_status: "Paid",
  //     buyer_payment_details: "Details",
  //     seller_payment_status: "Paid",
  //   },
  //   {
  //     id: "2",
  //     seller_name: "Nageswarao",
  //     invoice_number: "102",
  //     buyer_app: "mappls",
  //     amount: "500",
  //     buyer_payment_date: "13-09-2024",
  //     buyer_payment_status: "Paid",
  //     buyer_payment_details: "Details",
  //     seller_payment_status: "Paid",
  //   },
  //   {
  //     id: "3",
  //     seller_name: "Uday",
  //     invoice_number: "103",
  //     buyer_app: "Paytm",
  //     amount: "450",
  //     buyer_payment_date: "13-09-2024",
  //     buyer_payment_status: "Paid",
  //     buyer_payment_details: "Details",
  //     seller_payment_status: "Paid",
  //   },
  //   {
  //     id: "4",
  //     seller_name: "Nagarjuna",
  //     invoice_number: "104",
  //     buyer_app: "mappls",
  //     amount: "300",
  //     buyer_payment_date: "15-09-2024",
  //     buyer_payment_status: "Pending",
  //     buyer_payment_details: "Details",
  //     seller_payment_status: "Pending",
  //   },
  //   {
  //     id: "5",
  //     seller_name: "Vinod",
  //     invoice_number: "105",
  //     buyer_app: "Paytm",
  //     amount: "800",
  //     buyer_payment_date: "19-09-2024",
  //     buyer_payment_status: "Paid",
  //     buyer_payment_details: "Details",
  //     seller_payment_status: "Paid",
  //   },
  // ];

  const onActions = (data: any) => {
    console.log("dadadada");

    setShow(true);
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const handleClose = () => {
    setShow(false);
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  return (
    <div className="container-fluid content-bg-color">
      <div className="row align-items">
      <h4 className="primary-color fw-bold mt-3 col-md-6">ONDC Settlements</h4>
      <div className="col-md-6 text-end">
      {/* <button className="btn btn-primary me-2"><CiExport size={20} className="me-2"/>Export</button> */}
      {/* <button className="btn btn-primary">Export</button> */}
      </div>
      </div>
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={settlementData}
            TableCols={SettlementDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
      {/* <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>Buyer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="fw-bold">Buyer App </div>
              <div className="fw-bold">Amount </div>
              <div className="fw-bold">Date </div>
              <div className="fw-bold">Transaction Id</div>
            </div>
            <div className="col-6">
              <div>: Paytm</div>
              <div>: 1000</div>
              <div>: 15-09-2024</div>
              <div>: 123456</div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
