import React from "react";

interface Props {
  contentData?: any;
  customClass?: any;
  paymentPartners?: any;
  gamesContents?: any;
  blogsContent?: any;
  cardAction?: (data: any) => void;
}

export const CustomCard = (props: Props) => {
  const onCardAction = (e: any, data: any, type: any) => {
    e.stopPropagation();
    const actionData = { data: data, type: type };
    if (props?.cardAction) {
      props?.cardAction(actionData);
    }
  };

  return (
    <>
      {props?.contentData?.map((data: any, index: number) => (
        <div
          className={`${
            props?.customClass ? "col-lg-4" : "col-lg-12"
          } mb-4 cursor-pointer px-2`}
          key={index}
        >
          <a
            className={`text-dark shadow service-list d-flex justify-content-center align-items-center box-shadow`}
            style={{ backgroundColor: data?.backgroundColor }}
          >
            <div className={`service-list-item w-100 px-4`}>
              <div className="row align-items">
              <div className="col-md-2 text-white">{data?.icon}</div>
                <div className="col-md-10 text-center">
                  <h5 className="text-white">{data?.name}</h5>
                  <h4 className="text-white">{data?.count}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      ))}
    </>
  );
};
