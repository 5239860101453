import React, { useState } from "react";
import { Search } from "../../../../components/search/search";
import { Loader } from "../../../../components/loader";
import { RenewalPendingDataGridCols } from "./data-grid-cols";
import { DataTable } from "../../../../components/custom-table";
import { DateRangeSelector } from "../../../../components/date-filter/daterangeselector";

export const RenewalPending = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });

  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();

  const staticRenewalPendingData = {
    total: 10,
    results: [
      {
        bu_name: "Ashiravad pvt ltd",
        amount: "10,000",
        next_payment: "12-09-2024",
        last_paid: "12-08-2024",
      },
      {
        bu_name: "parle-G ltd",
        amount: "20,000",
        next_payment: "10-09-2024",
        last_paid: "10-08-2024",
      },
    ],
  };

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  return (
    <div className="content-bg-color">
      <h4 className="primary-color fw-bold mt-3">Reports</h4>
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector 
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
            <button className="btn btn-primary">Search</button>&nbsp;
            <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        <Loader LoadingReducer={loading} />
        <DataTable
          tableData={staticRenewalPendingData}
          TableCols={RenewalPendingDataGridCols}
          onActions={(e: any) => onActions(e)}
          onPageChange={(e: any) => onPageChange(e)}
          onSelectPageChange={(e) => onSelectPage(e)}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
};
