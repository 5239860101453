import { DataTableCol } from "../../../components/data-table/types";

export const PreviligesDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        type: 'link',
        action: 'link'
    },
    {
        title: 'Code',
        control: 'code',
        sortable: true,
    }
];