import React, { useEffect, useState } from "react";
import { ApisService } from "../../../../../app/core/apis.service";
import { dataDecrypt } from "../../../../../app/utility/encryption-helper";
import { Search } from "../../../../../components/search/search";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { DateRangeSelector } from "../../../../../components/date-filter/daterangeselector";
import moment from "moment";

export const InvoicesList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();
  const [transactionsData, setTransactionsData] = useState<any>([]);

  useEffect(() => {
    loadTransactions();
  }, []);

  async function loadTransactions(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.transactionApi(
        "findByTransactionBuId",
        "b4472d7db1311dbeb910c70ba02fbaae7386ebf0224fd956f0a1127bc958059f5fe897c87234b77ced62414746d66650b88fa471d55dbf12786f61a462efd175d34f569ff5b770ef86d87cdd10f11ede3986c6c36cfb3b407dfc80d9d2e978dfa363d091b977687958d71c038581332f71c81ca0dc57f9675ab80f5d716e0cfebf6b79e3fd1860e8d923bc12aa714085dece3962871cd6058e51ac42f8ce58fcd6bc779e51c399425d2d9ef751a7ed60"
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      console.log(transactionsData,"transactionsData");
      const response = transactionsData?.results;
      console.log(response,"response");
      if (Array.isArray(response)) {
        response.forEach((element: any) => {
          element.invoice_date = element?.invoice_details ? moment(element?.invoice_details[0]?.invoice_date).format("DD-MM-YYYY") : "";
          element.buyer_app = element?.ondc?.buyer_app;
          element.sold_to = element?.sold_to;
          element.invoice = element?.invoice_details ? element?.invoice_details[0]?.bill_no : "";
          element.invoice_amount = element?.invoice_details ? element?.invoice_details[0]?.invoice_amount : "";
          element.packing_status = (element?.invoice_details && element?.invoice_details[0]?.package_details) ? "completed" : "pending";
          element.delivery_status = (element?.invoice_details && element?.invoice_details[0]?.delivery_details) ? "completed" : "pending";
        });
      } else {
        console.error("Error: 'response' is either undefined or not an array.");
      }
      
      const orderData = response.filter((order: any) => order.type === "ONDC");
      console.log(orderData);
      setTransactionsData(orderData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={transactionsData}
            TableCols={TransactionsDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e: any) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
