import React, { useEffect, useState } from "react";
// import { S3Helper } from "../../../app/utils/s3-helper";
// import { GET_PRESIGNED_URL } from "../../../app/services/common.service";
import moment from "moment";
// import { Carousel } from "react-responsive-carousel";
interface Props {
  columns: number;
  userFormData: any;
  isEdit: boolean;
  onChildEvent?: (data: string) => void;
  onChangeEventDate?: (data: any) => void;
  onFormSubmitEvent?: (data: string) => void;
  customClass?: any;
  loading?: any;
  custom_button_text?: any;
  customClassName?: any;
  isNotEmptyFormAfterSubmit?: boolean;
  onParentDropdownEvent?: (data: any) => void;
}

export const DynamicForm = (props: Props) => {
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [formFieldsArray, setFormFieldsArray] = useState<any[]>([]);
  const [image, setImage] = useState<any>(null);
  const [images, setImages] = useState<any>([]);
  useEffect(() => {
    // if (!props?.isEdit) {
    const formsValues: any = {};
    props.userFormData.forEach((element: any) => {
      formsValues[element.name] = element.value ? element.value : "";
    });

    setFormData(formsValues);
    // }
    setFormFieldsArray([...props.userFormData]);
  }, [props]);
  console.log("formValues : ", props?.userFormData);

  const handleInput = (e: any, index: number, data: any) => {
    const formData = formFieldsArray;
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: data?.type === "checkbox" ? e.target.checked : value,
    }));
    const field = formFieldsArray[index];

    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name];
    }

    formData?.forEach((el: any) => {
      if (el?.name === "event_start_date") {
        if (el?.value === "" && field?.isEndDataValidate) {
          newErrors['event_start_date'] = `Please select start date`;
        } else {
          delete newErrors[name];
        }
      }
    })
    formData[index].value =
      data?.type === "checkbox" ? e.target.checked : value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
    if (field?.isParentDropDown) {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({ name, value });
      }
    }
  };

  const handleInputFileUpload = (e: any, index: number, data: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    // const imageData = e.target.files[0];
    // const imagesList = [];
    // imagesList.push(imageData.name);
    // let payload = {
    //   images: imagesList
    // }
    // console.log("payload : ", payload)
    // GET_PRESIGNED_URL(payload).then((res: any) => {
    //   if (res) {
    //     const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_urls[imageData.name],
    //       imageData,
    //       imageData.type,
    //     );
    //     const formData = formFieldsArray;
    //     const { name, value } = e.target;
    //     const field = formFieldsArray[index];
    //     const { regex, label } = field;
    //     const newErrors: any = { ...errors };
    //     if (regex && value && !RegExp(regex).test(value)) {
    //       newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    //     } else {
    //       delete newErrors[name];
    //     }
    //     formData[index].value = res.data?.presigned_urls[imageData.name].split('?')[0];
    //     setFormData((prevData: any) => ({
    //       ...prevData,
    //       [name]: res.data?.presigned_urls[imageData.name].split('?')[0],
    //     }));
    //     setImage(res.data?.presigned_urls[imageData.name].split('?')[0]);
    //     console.log("formdata : ", formData);
    //     // const file = e.target.files[0];
    //     // if (file) {
    //     //   const reader = new FileReader();
    //     //   reader.onloadend = () => {
    //     //     setImage(reader.result);
    //     //   };
    //     //   reader.readAsDataURL(file);
    //     // }
    //     console.log("updated formData = :", formData);
    //     setFormFieldsArray([...formData]);
    //     setErrors(newErrors);
    //   }
    // })
  };


  const handleInputMultipleFileUpload = (event: any, index: any, data: any) => {
    // const files = event.target.files;
    // const fileArray: any = Array.from(files).map((file: any) => URL.createObjectURL(file));
    // setImages(prevImages => prevImages.concat(fileArray));
    // Array.from(files).map((file: any) => URL.revokeObjectURL(file));

    const imageData = event.target.files;
    const imagesList: any = [];
    Array.from(imageData).map((file: any) => {
      imagesList.push(file.name);
    });
    let payload = {
      images: imagesList
    }

    // GET_PRESIGNED_URL(payload).then((res: any) => {
    //   if (res) {
    //     const URLS: any = [];
    //     Array.from(imageData).map((file: any) => {
    //       const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_urls[file.name],
    //         file,
    //         file.type,
    //       );
    //       URLS.push(res.data?.presigned_urls[file.name].split('?')[0])
    //     });
    //     const formData = formFieldsArray;
    //     const { name, value } = event.target;
    //     formData[index].value = URLS;
    //     setFormData((prevData: any) => ({
    //       ...prevData,
    //       [name]: URLS
    //     }));
    //     setImages([...URLS])
    //     setFormFieldsArray([...formData]);
    //   }
    // })
  };

  const onFormSubmit = (e: any, btnObj: any) => {
    if (btnObj.isCheckFormValidation) {
      e.preventDefault();
      const isValid = validateForm();
      if (isValid) {
        setErrors({});
        const data: any = { formData, type: btnObj?.action };
        if (props?.onFormSubmitEvent) {
          props?.onFormSubmitEvent(data);
        }
        if (!props?.isNotEmptyFormAfterSubmit) {
          formFieldsArray.forEach((element: any) => {
            element.value = "";
          });
        }
      }
    } else {
      const data: any = { formData, type: btnObj?.action };
      if (props?.onFormSubmitEvent) {
        props?.onFormSubmitEvent(data);
      }
    }
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    formFieldsArray?.forEach((field: any) => {
      const { name, label, required, regex, error } = field;
      const value =
        typeof formData[name] === "string"
          ? formData[name]?.trim() || field.value
          : formData[name] || field.value;
      if (required && (!value || value === "")) {
        newErrors[name] = error || `Please enter a ${label?.toLowerCase()}`;
        isValid = false;
      }
      if (regex && value && !RegExp(regex).test(value)) {
        newErrors[name] =
          error || `Please enter a valid ${label?.toLowerCase()}`;
        isValid = false;
      }
    });
    setErrors(newErrors);
    return isValid;
  };

  const handleSubElementInput = (e: any, index: number, data: any) => {
    const formData = formFieldsArray;
    const { name, value } = e.target;
    const parentElementDate = formData.find((subElement: any) => subElement?.name === data?.childObject?.parentElement);
    if (parentElementDate && e.target.checked) {
      formData[index].value = parentElementDate.value;
    } else {
      formData[index].value = '';
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [formData[index].name]: formData[index].value,
    }));

    const field = formFieldsArray[index];

    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[formData[index].name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[formData[index].name];
    }
    setErrors(newErrors);
    setFormFieldsArray([...formData]);
  }
  return (
    <div className="row">
      {formFieldsArray?.map((data: any, index: number) => (
        <React.Fragment key={index}>
          {data.title === "input" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 ${data?.formFloating ? "form-floating" : ""}  text-start`}
            >
              {!data?.formFloating && (
                <label className="form-label d-flex justify-content-start">
                  {data.label}
                  {(data.required && data?.hideMandatoryStar) && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}{data?.childObject && <>
                    &nbsp;
                    &nbsp;
                    <input
                      type={data?.childObject.type}
                      name={data?.childObject.name}
                      id={data?.childObject?.id}
                      className={`${data.childObject?.type === "checkbox" ? "" : "form-control border-primary-medium"}`}
                      placeholder={data.childObject?.placeholder}
                      onChange={(event) => {
                        handleSubElementInput(event, index, data);
                      }}
                      required={data.childObject?.required}
                      min={data?.childObject?.min}
                      max={data?.childObject?.max}
                      maxLength={data.childObject?.maxLength}
                      value={data.childObject?.value}
                      disabled={data?.childObject?.isDisabled}
                    />
                    <span className="ms-2">({data.childObject?.label})</span>
                  </>}
                </label>
              )}
              <input
                type={data.type}
                name={data.name}
                id={data?.id}
                className={`${data.type === "checkbox" || data.type === "color" ? "mx-auto" : "form-control border-primary-medium"}`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                min={data?.min}
                max={data?.max}
                maxLength={data.maxLength}
                value={data?.type === "date" ? moment(data?.value).format("YYYY-MM-DD") : data.value}
                checked={data?.value}
                disabled={data?.isDisabled}
              />
              {data?.formFloating && (
                <label className="form-label ms-3 d-flex justify-content-start">
                  {data.label}
                  {data.required && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}
                </label>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "dropdown" && (
            <div className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`} mb-2`}>
              <label className="form-label d-flex justify-content-start">
                {data.label} {data.required}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <select
                name={data.name}
                disabled={data?.isDisabled}
                className="form-select custom-select border-primary-medium"
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                value={data.value}
              >
                <option value="" disabled={data?.required}>
                  {data.placeholder}
                </option>
                {data.option?.map((option: any, i: number) => {
                  return (
                    <option key={i} value={option?.value}>
                      {option?.label}
                    </option>
                  );
                })}
              </select>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "textarea" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <textarea
                name={data.name}
                rows={data?.rows}
                className={`${data.type === "checkbox" ? "" : "form-control border-primary-medium"}`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                maxLength={data.maxLength}
                value={data.value}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {(data.title === "file" && !data.isMultipleImages) && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              {data?.value && data.accept !== 'application/pdf' && (
                <div style={{ height: '150px', width: "200px", marginBottom: data?.value ? "20px" : "" }}>
                  <img src={data?.value} alt="image" className={`rounded ${data?.value ? "my-2" : ""}`} style={{ height: '100%', width: "100%", borderRadius: "20%" }} />
                </div>
              )}
              <input className="mt-4 d-none" id={`InputFile${index}`} type="file" name={data.name} accept={data.accept} onChange={(event) => {
                handleInputFileUpload(event, index, data);
              }} />
              <label htmlFor={`InputFile${index}`} className={`btn btn-primary btn-sm f16 px-4 border-0 ${data?.value ? "mt-0" : "mt-1"}`} >
                Upload
              </label>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}

          {/* {(data.title === "file" && data.multipleImagesUpload) && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`} mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              {(images.length > 0 || data?.value) && data.accept !== 'application/pdf' && (
                <div className="d-flex flex-wrap" style={{ gap: "10px", marginBottom: data?.value.length > 0 ? "20px" : "" }}>
                  <Carousel showArrows={true} showThumbs={false}>
                    {data?.value?.map(
                      (data: any) => {
                        return (
                          <div className="border rounded-2" style={{ height: '250px', width: "100%" }}>
                            <img src={data} className="d-block " style={{ height: '100%', width: "100%", objectFit: "cover" }} />
                          </div>
                        );
                      }
                    )}
                  </Carousel>
                  {data?.value?.length == 0 && (
                    <div className='text-center my-2'>No records found</div>
                  )}
                </div>
              )}

              <input className="mt-4 d-none" id={`InputFile${index}`} type="file" name={data.name} accept={data.accept} multiple onChange={(event) => {
                handleInputMultipleFileUpload(event, index, data);
              }} />
              <label htmlFor={`InputFile${index}`} className={`btn btn-primary btn-sm f16 px-4 ${data?.value.length > 0 ? "mt-0" : "mt-1"}`} onChange={(event) => {
                handleInputFileUpload(event, index, data);
              }}>
                Upload
              </label>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )} */}

          {data?.type === 'button' && <div className={`${data?.class}`}>
            {data?.buttons.map((button: any, btnIndex: number) => {
              return <>
                <button type="submit" key={btnIndex} disabled={button?.isDisabled}
                  className={`btn d-block d-lg-inline-block mb-0 mb-lg-0 w-xs-100 rounded-1 ${button?.class}`} onClick={(e) => onFormSubmit(e, button)} >
                  {props?.loading ? 'Loading...' : button?.title}
                </button>
              </>
            })}
          </div>}
        </React.Fragment>
      ))}
    </div>
  );
};