import { CustomDataTableCol } from "../../../../components/custom-table/types";

export const SubscriptionsDataGridCols: CustomDataTableCol[] = [
    {
        title: 'Bu Name',
        control: 'bu_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Amount',
        control: 'amount',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Next Payment',
        control: 'next_payment',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Last Paid',
        control: 'last_paid',
        isDateFormat: true,
        sortable: true,
        canShowColumn: true
    },
];