import axios from 'axios';
import { AppConfig } from './../config';

export const API = axios.create({
    baseURL: AppConfig.BASE_URL
});


API.interceptors.request.use(function (config) {
    config.headers['Content-Type'] = 'application/json';
    return config;
});

API.interceptors.response.use(
    (response) => {
        if (response.data) {
            return response.data;
        }
        else {
            return response.data ? response.data : {};
        }
    }, (error) => {
        throw new Error(error);
    }
);