import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DynamicForm } from "../../components/new-formbuilder/form";
import Logo from "./../../assets/images/images-and-icons/vyapar_logo.png";
import toast from "react-hot-toast";
import { ApisService } from "../../app/core/apis.service";
import { dataDecrypt, encrypt } from "../../app/utility/encryption-helper";

export const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate();

  const loginFormData = [
    {
      title: "input",
      control: "",
      type: "text",
      label: "Mobile Number",
      name: "mobile_number",
      value: "",
      required: true,
      regex: /^[6-9]\d{9}$/,
      maxLength: "",
      placeholder: "Please enter mobile number",
      error: "Please enter a valid 10-digit mobile number",
      submitButtonName: "Login",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      control: "",
      type: "password",
      label: "Password",
      name: "password",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Please enter password",
      error: "Please enter password",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-center my-2",
      buttons: [
        {
          title: "Login",
          action: "add",
          isCheckFormValidation: true,
          class: "btn btn-primary rounded-pill border-0 w-25",
        },
      ],
    },
  ];

  const onFormSubmitEvent = async (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = event?.formData;
      const payload = {
        contact: formData?.mobile_number,
        password: formData?.password,
      };
      let encryptedData = encrypt(JSON.stringify(payload));
      setLoading(true);
      try {
        const res = await ApisService.usersApi("login", encryptedData);
        const userData = JSON.parse(dataDecrypt(res?.data?.data));
        setLoading(false);
        if (userData?.error) {
          toast.error(userData?.message, {
            duration: 2000,
            position: "top-right",
          });
        } else {
          toast.success("Logged in successfully", { position: "top-right" });
          sessionStorage.setItem("user_role", "SUPERADMIN");
          sessionStorage.setItem("user_name", userData?.user_name);
          sessionStorage.setItem("jwt_token", userData?.firebase_token);
          navigation("/dashboard/info");
        }
      } catch (error) {
        toast.error("Invalid Credentials", {
          duration: 2000,
          position: "top-right",
        });
        setLoading(false);
      }

      // if (formData?.email === "superadmin@gmail.com" && formData?.password === "123456") {
      //     toast.success("Logged in successfully", { position: 'top-right' })
      //     sessionStorage.setItem('user_role', 'SUPERADMIN');
      //     sessionStorage.setItem('user_name', 'Super Admin');
      //     // sessionStorage.setItem("jwt_token", "9y3esz22ntGgt29lgSqzNy0c1w6eA3sc2eCJ7AzCHkmp33At");
      //     navigation("/dashboard/info");
      //     setLoading(false);
      // } else {
      //     toast.error("Invalid Credentials", { position: 'top-right' })
      //     navigation("/");
      //     setLoading(false);
      // }
      // setLoading(true);
      // LOGIN(formData).then((res: any) => {
      //     if (res.status === "error") {
      //         toast.error(res.error, { duration: 2000, position: 'top-right', });
      //         setLoading(false);
      //     } else {
      //         if (res) {
      //             if (res?.data?.user_role === "SUPERADMIN") {
      //                 sessionStorage.setItem('user_uuid', res?.data?.user_uuid)
      //                 sessionStorage.setItem('user_role', res?.data?.user_role);
      //                 sessionStorage.setItem('user_name', res?.data?.username ? res?.data?.username : "Super Admin");
      //                 sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
      //                 toast.success(res.message, { duration: 2000, position: 'top-right', })
      //                 navigation("/dashboard/view");
      //                 setLoading(false);
      //             } else {
      //                 toast.error("Invalid Credentials", { duration: 2000, position: 'top-right', })
      //                 navigation("/")
      //                 setLoading(false);
      //             }
      //         }
      //     }
      // });
    }
  };

  // const navigate = useNavigate();

  // const onClickLogin = () => {
  //     navigate("/dashboard/invitation");
  // }
  return (
    // <div className='container-fluid '>
    //     <div className='row'>
    //         <div className='col-10 col-sm-6 col-md-6 col-lg-4 mx-auto my-5 login-form'>
    //             <div className='d-flex flex-column justify-content-center my-auto'>
    //                 <form className="m-4">
    //                     <div className="Login-form-content">
    //                         <h3 className="Login-form-title text-center">Log In</h3>
    //                         <div className="mt-3">
    //                             <label className='form-label d-flex justify-content-start text-start'>Email</label>
    //                             <input type="email" name="email" className="form-control mt-1" placeholder="Enter email" />
    //                             <div className=" mt-3">
    //                                 <label className='form-label d-flex justify-content-start'>Password</label>
    //                                 <input type="password" name="password" className="form-control mt-1" placeholder="Enter password" />
    //                             </div>
    //                         </div>
    //                         <div className="text-center mt-3">
    //                             <button className="custom-bg-btn border-0 py-1 text-white px-5 mt-2" onClick={onClickLogin} >Login</button>
    //                         </div>
    //                         <div className='d-flex flex-column justify-content-center '>
    //                             <div className='my-2'><NavLink to="/forgot-password" className="text-decoration-none text-dark">Forgot password</NavLink></div>
    //                             <div>Don't have an account? <NavLink to="/register" className="text-decoration-none"><span className='custom-text-color'>Register</span></NavLink></div>
    //                         </div>
    //                     </div>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-transparent border-0">
          <div className="bg_yellow shadow rounded-5">
            <div className="rounded-3 d-flex flex-column justify-content-center">
              <div className="">
                <form className="m-4">
                  <div className="Login-form-content mt-5">
                    <h2 className="Login-form-title login_logo text-center fw-bold mt-5 p-5">
                      <img src={Logo} alt="" className="my-4" />
                    </h2>
                    <h4 className="Login-form-title text-center fw-bold my-4">
                      LOGIN
                    </h4>
                    <DynamicForm
                      userFormData={loginFormData}
                      columns={1}
                      isEdit={false}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={loading}
                      isNotEmptyFormAfterSubmit={true}
                    />
                    <div className="d-flex justify-content-center ">
                      <div className="my-2 text-primary fw-bold">
                        <NavLink
                          to="/forgot-password"
                          className="text-decoration-none text-primary fw-bold"
                        >
                          Forgot password?
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
