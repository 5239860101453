import { CustomDataTableCol } from "../../custom-table/types";

export const OrganizationUsersDataGridCols: CustomDataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'personal_email_id',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Mobile Number',
        control: 'contact',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Status',
        control: 'status',
        isUserActivityStatus:true,
        sortable: true,
        canShowColumn: true
    }];