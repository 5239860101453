import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { CustomDataTableCol } from "./types";


interface Props {
  tableData: any;
  TableCols: CustomDataTableCol[];
  alertTableStatus?: any;
  showViewModal?: any;
  alterShowViewData?: (data: any) => void;
  onActions: (data: string) => void;
  onPageChange: (data: any) => void;
  pageCount: any;
  onSelectPageChange?: (data: any) => void;
  isUnAssignedTable?: boolean;
  isHidePagination?: boolean;
  abandonedCartTooltipMessage?: boolean;
  tableLoading?: any;
}

export const DataTable: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [itemOffset, setItemOffset] = useState(props?.pageCount?.page);
  const [selectAll, setSelectAll] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(props?.pageCount?.limit);
  const [canShowDefaultArrow, setCanShowDefaultArrow] = useState(true);
  const items: any[] = props?.tableData?.results || [];
  const pageCount = Math?.ceil(props?.tableData?.total / itemsPerPage);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [page, setPage] = React.useState(0);
  // const startIndex = page * rowsPerPage;
  // const currentPageData = items?.slice(startIndex, startIndex + rowsPerPage);
  // console.log("current page data : ", currentPageData)
  // const [totalItems, setTotalItems] = useState<any>(currentPageData);
  const itemPerPage = [10, 25, 50, 100, 500];

  useEffect(() => {
    if (props?.tableData) {
      const isSelectAll = items.find((data: any) => !data?.is_select);
      if (!isSelectAll) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [props?.tableData]);

  const onChangeItemPerPage = (value: any) => {
    console.log("page change : ", value)
    props.onPageChange(value);
    setItemsPerPage(value);
  };

  const onSortingField = (data: any) => {
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      const preparedData = props?.tableData?.results?.sort(
        (a: any, b: any) =>
          reversed * a[data]?.toString().localeCompare(b[data])
      );
    }
  };

  const handlePageClick = (e: any, value: any) => {
    if (props.onSelectPageChange) {
      props.onSelectPageChange((value - 1));
    }
    setItemOffset((value - 1));
  };

  const onAction = (data: any, type: string) => {
    const actionData: any = { data, type };
    props.onActions(actionData);
  };

  const timeFormat = (totalSeconds: any) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };

  const handleSelectAllCheckBox = (e: any) => {
    const status = e.target.checked;
    setSelectAll(status)
    const actionData: any = { type: 'checkAll', selected: status };
    props.onActions(actionData);
  }

  const handleChangeEachCheckBox = (e: any, data: any, index: number) => {
    const status = e.target.checked;
    const actionData: any = { data, type: 'check', selected: status, index: index };
    props.onActions(actionData);
  }

  const handleChangePermissions = (e: any, index: number) => {
    const status = e.target.checked;
    items[index][e.target.name] = status
    const actionData: any = { items, type: 'Change' };
    props.onActions(actionData);
  }

  const replaceUnderscores = (str: any) => {
    if (!str) return '';
    return str.replace(/_/g, ' '); // Replace all underscores with spaces
  };

  // const handleChangePage = (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   newPage: number
  // ) => {
  //   if (props?.onPageChange) {
  //     props?.onPageChange(newPage)
  //   }
  //   if (props?.tableLoading) {
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //   }
  //   const startIndex = newPage * rowsPerPage;
  //   setTotalItems([...items?.slice(startIndex, startIndex + rowsPerPage)])
  //   onSortingField(props?.pageCount?.sorting_field);
  //   setPage(newPage);
  // };


  return (
    <div>
      <div className="container-fluid px-0">
        <div className="row px-0">
          <div className="col-12">
            <div className="table-responsive rounded-3">
              <table className="table table_new_ui">
                <thead className="text-center">
                  <tr className="border border-primary-medium fw-bold">
                    {props?.TableCols?.map((el, index) => (
                      <React.Fragment key={index}>
                        {el.canShowColumn && (
                          <th className={`text-start fw-bold fs-6`}
                            onClick={() => el.sortable ? onSortingField(el.control) : null}
                          >
                            {<div className={`${props?.isUnAssignedTable ? "table-padding" : ""}`}><span>{el?.title}</span>
                              <span>
                                {canShowDefaultArrow && index === 0 && !sortingField && el.sortable && (
                                  <i className="bi bi-arrow-down cursor-pointer p-2 "></i>
                                )}
                                {sortingField && sortingField === el.control && (
                                  <i
                                    className={`${sortingOrder === "asc"
                                      ? "bi bi-arrow-down ms-1 fw-bold cursor-pointer "
                                      : "bi bi-arrow-up ms-1 fw-bold cursor-pointer"
                                      }`}
                                  ></i>
                                )}</span>
                            </div>}
                            {el?.isSelect && (
                              <div className="text-center">
                                <label className=" ">
                                  <input type="checkbox" className="form-check cursor-pointer container_checkbox" checked={selectAll} onChange={handleSelectAllCheckBox} />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            )}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center" >
                  {items &&
                    items?.map((data: any, i: number) => (
                      <tr key={i} className="border-bottom">
                        {props?.TableCols?.map((el: any, index: number) => (
                          <React.Fragment key={index}>
                            <td className="text-start">
                              {el.control === "serial_no" && (
                                <span>{i + 1}</span>
                              )}
                              {/* {el.control === "from_name" &&  (
                                <span className="fw-bold">{data[el?.control]}</span>
                              )}
                               {el.control === "name" &&  (
                                <span className="fw-bold">{data[el?.control]}</span>
                              )} */}
                              {el.control === "is_active" && (
                                <span>
                                  {data[el.control] === 1
                                    ? "Active"
                                    : "In-Active"}
                                </span>
                              )}
                              {el.isArrayToString && (
                                <span>{data[el.control]?.toString()}</span>
                              )}
                              {el.control !== "is_active" &&
                                el.control !== "interest_id" &&
                                !el.isArrayToString &&
                                !el.isLink &&
                                !el.isTextOverflowHide &&
                                !el?.isBold &&
                                el?.title !== "Role" &&
                                el?.control !== "plan_status" &&
                                !el?.isStatus &&
                                !el?.isDateFormat &&
                                !el?.isDateTimeFormat &&
                                !el?.isInput &&
                                !el?.isTimeFormat &&
                                !el.isServiceStatus &&
                                !el?.isSelect &&
                                !el?.isPaymentStatus &&
                                !el?.isAddressView &&
                                !el?.isCustomerPaymentStatus &&
                                !el?.isUserActivityStatus &&
                                !el?.isOrderStatus &&
                                !el?.isOrderVehicleDetails &&
                                !el?.isDMVDetails &&
                                !el?.isReply &&
                                !el?.isProfileImage &&
                                !el?.isUserPaymentStatus &&
                                !el?.isShowDmvDetails &&
                                !el?.isServiceFee &&
                                !el?.isProductStatus &&
                                (
                                  <span>{data[el.control] ? data[el.control] : "-"}</span>
                                )}
                              {el?.isBold && (
                                <span className="fw-bold">{data[el?.control]}</span>
                              )}
                              {el?.title === "Role" && (
                                <span>{data[el?.control]?.replace(/_/g, ' ')}</span>
                              )}
                              {el?.isProfileImage && (
                                <div className="fw-bold cursor-pointer" onClick={() => onAction(data, "Coupon Details")}>
                                  <span className="me-2 "><img src={data?.image} className="coupon-profile-image" /></span>
                                  <span>{data[el?.control]}</span>
                                </div>
                              )}
                              {el?.control === "plan_status" && (
                                <span className="fw-bold text-uppercase">{data[el.control] === "active" ? <span className="text-success">Active</span> : <span className="text-danger">In active</span>}</span>
                              )}
                              {el?.isAddressView && (
                                <span className="cursor-pointer" title={data[el?.control]}>{data[el?.control]?.length > 20
                                  ? `${data[el?.control].substring(0, 20)} ...`
                                  : data[el?.control]}</span>
                              )}
                              {el?.isCustomerPaymentStatus && (
                                <span>{data[el?.control] === "SUCCESS" ? <span className="text-success fw-bold">SUCCESS</span> : <span className="text-danger fw-bold">FAIL</span>}</span>
                              )}
                              {el?.isServiceFee && (
                                <span>{(data[el?.control] ? data[el?.control]?.replace(/_/g, ' ').toUpperCase() : "")}</span>
                              )}
                              {el?.isProductStatus && (
                                <span>{data[el?.control] === 1 ? <span className="text-success fw-bold">In Stock</span> : <span className="text-danger fw-bold">Out of stock</span>}</span>
                              )}
                              {el.isLink && (
                                <span className="custom_link" onClick={() => onAction(data, "link")}>{data[el.control]?.toString()}</span>
                              )}
                              {el?.isSelect && (
                                <div className="text-center mx-auto d-flex justify-content-center">
                                  <input type="checkbox" name="is_select" className="form-check cursor-pointer container_checkbox" checked={data[el?.control]} onChange={(e) => handleChangeEachCheckBox(e, data, i)} />
                                </div>
                              )}
                              {el?.isStatus && (
                                <>{data[el.control] === "COMPLETED" || data[el.control] === 1 ? <span className="text-success fw-bold text-uppercase">Completed</span> : <span className="text-danger fw-bold text-uppercase">Pending</span>} </>
                              )}
                              {el?.isUserActivityStatus && (
                                <>{data[el.control] === "Active" || data[el?.control] === 1 ? <span className="text-success fw-bold text-uppercase">Active</span> : <span className="text-danger fw-bold text-uppercase">In Active</span>}</>
                              )}
                              {el?.isOrderStatus && (
                                <>{data[el?.control] === "DELIVERED" && <span className="text-success fw-bold text-uppercase me-1 pe-2">Delivered</span>}
                                  {data[el?.control] === "PROCESSED" && <span className="text-warning fw-bold text-uppercase me-1 pe-1">Processed</span>}
                                  {data[el?.control] === "SHIPPED" && <span className="text-primary fw-bold text-uppercase me-3 pe-2">Shipped</span>}
                                  {data[el?.control] === "PENDING" && <span className="text-danger fw-bold text-uppercase me-3 pe-2">Pending</span>}
                                </>
                              )}
                              {el?.isOrderStatus && el?.isShowShippingDetails && (
                                <><button onClick={() => onAction(data, "showShipping")} className={`btn d-block d-lg-inline-block mb-0 mb-lg-0 border-0 w-xs-100 rounded-1 btn btn-primary  btn-sm f16 me-1 height_30 `}>Shipping Details</button></>
                              )}
                              {el?.isPaymentStatus && (
                                <> {data[el.control] === "COMPLETED" ? <span className="text-success fw-bold text-uppercase">Completed</span> : <span className="text-danger fw-bold text-uppercase">Pending</span>}</>
                              )}
                              {el?.isUserPaymentStatus && (
                                <>{data[el?.control] === "SUCCESS" && <span className="text-success fw-bold text-uppercase">SUCCESS</span>}
                                  {data[el?.control] === "VALIDATION_FAILED" && <span className="text-warning fw-bold text-uppercase">VALIDATION FAILED</span>}
                                  {data[el?.control] === "INPROGRESS" && <span className="text-primary fw-bold text-uppercase">INPROGRESS</span>}
                                  {data[el?.control] === "PENDING" && <span className="text-danger fw-bold text-uppercase">PENDING</span>}
                                  {data[el?.control] === "FAILED" && <span className="text-danger fw-bold text-uppercase">FAILED</span>}
                                </>
                              )}
                              {el?.isServiceStatus && (
                                <>{data[el?.control] === "COMPLETED" && <span className="text-success fw-bold text-uppercase">Completed</span>}
                                  {data[el?.control] === "SCHEDULED" && <span className="text-primary fw-bold text-uppercase">Scheduled</span>}
                                  {data[el?.control] === "INPROGRESS" && <span className="text-primary fw-bold text-uppercase">In progress</span>}
                                  {data[el?.control] === "PENDING" && <span className="text-danger fw-bold text-uppercase">PENDING</span>}
                                  {data[el?.control] === "DELAYED" && <span className="text-danger fw-bold text-uppercase">DELAYED</span>}
                                  {data[el?.control] === "DECLINED" && <span className="text-danger fw-bold text-uppercase">DECLINED</span>}
                                </>
                              )}
                              {el.isTextOverflowHide && (
                                <Tooltip title={data[el.control]} placement="top" arrow className="custom-tooltip-position" >
                                  <span>
                                    {data[el.control]?.length > 100 ? `${data[el.control].slice(0, 100)}...` : `${data[el.control] ? data[el.control] : ""}`}
                                  </span>
                                </Tooltip>
                              )}
                              {el?.isDateFormat && (
                                <span>{data[el.control] === "" || data[el.control] === null ? "-" : moment(data[el.control]).format("DD-MM-YYYY")}</span>
                              )}
                              {el?.isDateTimeFormat && (
                                <span>{data[el.control] === "" || data[el.control] === null ? "-" : moment(data[el.control]).format("DD-MM-YYYY HH:mm:ss")}</span>
                              )}
                              {el?.isTimeFormat && (
                                <>{timeFormat(data[el.control])}</>
                              )}
                              {el?.isInput && (
                                <span><input type="checkbox" name={el?.control} className="form-check-input cursor-pointer" checked={data[el?.control] == 1 ? true : false} onChange={(e) => handleChangePermissions(e, i)} /></span>
                              )}
                              {el.title === "Actions" && (
                                <div className="actions-view">
                                  <span>
                                    {el.isEdit && (
                                      <i
                                        className="bi bi-pencil-square settings-icon-action cursor-pointer edit-icon p-2"
                                        onClick={() => onAction(data, "Edit")}
                                      ></i>
                                    )}
                                    {el.isDelete && (
                                      <i
                                        className="bi bi-trash text-danger settings-icon-action fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => onAction(data, "Delete")}
                                      ></i>
                                    )}
                                    {el?.isView && (
                                      <i title={el?.isVehicleDetails ? "Vehicle Details" : "Work Details"}
                                        className="bi bi-eye-fill text-dark settings-icon-action fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => onAction(data, "View")}
                                      ></i>
                                    )}
                                    {el?.isHistory && (
                                      <i title="History"
                                        className="bi bi-clock-history text-dark settings-icon-action fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => onAction(data, "history")}
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              )}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {items?.length === 0 && (
              <div className="mt-3 text-center">No records found.</div>
            )}
            {!props?.isHidePagination && <div className="row my-2">
              <div className="col-md-4 text-start mt-1">
                {items?.length > 0 && (<>
                  <span className="me-2 text-dark">Items per page</span>
                  <select
                    onChange={(e) => onChangeItemPerPage(e.target.value)}
                    value={itemsPerPage}
                  >
                    {itemPerPage.map((page: number, index: number) => {
                      return (
                        <option key={index} value={page}>
                          {page}
                        </option>
                      );
                    })}
                  </select>
                </>)}
              </div>
              <div className="col-md-8 d-flex justify-content-end ">
                {items?.length > 0 && (
                  <div>
                    <Pagination
                      count={pageCount}
                      defaultPage={itemOffset + 1}
                      showFirstButton
                      className="mx-2"
                      showLastButton
                      variant="outlined"
                      shape="rounded"
                      onChange={(e: any, value: any) => handlePageClick(e, value)}
                    />
                  </div>
                )}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};