import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderLogo from './../../assets/images/images-and-icons/vyapar_logo.png'
import { DashboardRoutes } from '../../Routes/dashboard';

export const Dashboard = () => {
    const [body, setBody] = useState<any>("inside-bg");
    const [toggleIcon, setToggleIcon] = useState<any>("nav_toggler");
    const [header, setHeader] = useState<any>("header");
    const [nav, setNav] = useState<string>("l-navbar");
    const [icon, setIcon] = useState<string>("bx bx-menu");
    const [isOffCanvasOpen, setIsOffCanvasOpen] = useState<boolean>(false);
    const [activeMenu, setActiveMenu] = useState<any>(null);
    const [userRole, setUserRole] = useState("");
    const [navItems, setNavItems] = useState([]);
    const navigation = useNavigate();
    const username = sessionStorage.getItem('user_name');

    useEffect(() => {
        const userRole = sessionStorage.getItem('user_role');
        handleMenusItems(userRole);
    }, [])

    const navToggle = (): void => {
        toggleIcon === "nav_toggler" ? setToggleIcon("nav_toggler toggle") : setToggleIcon("nav_toggler");
        body === "inside-bg" ? setBody("body-pd") : setBody("inside-bg");
        header === "header" ? setHeader("header body-pd") : setHeader("header");
        nav === "l-navbar" ? setNav("l-navbar show") : setNav("l-navbar");
        icon === "bx bx-menu" ? setIcon("bx bx-x") : setIcon("bx bx-menu");
    }

    const handleMenusItems = (roleData: any) => {
        let navItems: any = [];
        switch (roleData) {
            case 'SUPERADMIN':
                navItems = [
                    { path: '/dashboard/info', icon: 'bi bi-speedometer2', name: 'Dashboard' },
                    { path: '/dashboard/registration', icon: 'bi bi-card-checklist', name: 'Registrations' },
                    { path: '/dashboard/menu-system', icon: 'bi bi-diagram-3', name: 'Master Menu' },
                    { path: '/dashboard/previliges', icon: 'bi bi-gear-wide-connected', name: 'Previliges' },
                    { path: '/dashboard/organizations', icon: 'bi bi-grid', name: 'Business Units' },
                    { path: '/dashboard/settlements', icon: 'bi bi-sliders', name: 'ONDC Settlements' },
                    { path: '/dashboard/subscriptions', icon: 'bi bi-grid', name: 'Subscriptions' },
                    // { path: '/dashboard/invitation', icon: 'bi bi-people', name: 'Invitation' },
                    // { path: '/dashboard/menu-system', icon: 'bi bi-diagram-3', name: 'Menu System' },
                    {
                        path: "/dashboard/reports",
                        icon: "bi bi-layout-text-sidebar-reverse",
                        name: "Reports",
                        // submenu: [
                        //     { path: "/dashboard/reports/renewal-pending", name: "Renewal Pending" },
                        //     { path: "/dashboard/reports/subscriptions", name: "Subscriptions" },
                        // ],
                    },
                ];
                setNavItems(navItems);
                break;
            default:
                break;
        }
    }

    const onClickLogout = () => {
        navigation("/")
        sessionStorage.clear();
        toast.success("Logged out successfully", { position: 'top-right' })
    }

    const onShowMobileSideMenu = () => {
        setIsOffCanvasOpen(true);
    }

    const onNavLinkClick = () => {
        setIsOffCanvasOpen(false);
    };

    const handleMenuClick = (index: number) => {
        setActiveMenu(activeMenu === index ? null : index);
    };

    return (
        <div>
            <body className={body}>
                <div className="py-2">
                    <header className={`${header}  border-2 `} id="header">
                        <div className="header_toggle" onClick={navToggle}> <i className={icon} id="header-toggle"></i> </div>
                        <h2 className='text-primary text-start mx-md-auto nav_logo mb-0 d-md-none bg-transparent ps-2'>
                            <img src={HeaderLogo} alt="" className='main-logo rounded-3' />
                        </h2>
                        <div className='d-md-none ms-auto me-2'>
                            <button className="bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" onClick={onShowMobileSideMenu}><i className={`bi bi-list fs-1 mx-2 fw-bold`} style={{ color: " #2d7381" }}></i></button>
                            <div className={`offcanvas offcanvas-start w-75 ${isOffCanvasOpen ? "" : "offcanvas-backdrop-show"}`} data-bs-scroll="true" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style={{ display: isOffCanvasOpen ? "block" : "none" }}>
                                {/* <button type="button" className="btn-close text-reset mt-3 ms-auto text-dark position-relative" style={{ left: "87%" }} data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                                <div className="offcanvas-body ">
                                    <>
                                        <div className='secondary-bg rounded-2 p-1 mb-2'>
                                            <div className='d-flex'>
                                                <div>
                                                    <img src={HeaderLogo} className='rounded-3 w-100' style={{ maxWidth: "100%", height: "15vh", objectFit: "contain" }} />
                                                </div>
                                                <div className='my-auto ms-2'>{username}</div>
                                            </div>
                                        </div>
                                        <div className='mobile-menu-scroll'>
                                            {navItems.map((item: any, index: number) => (
                                                <NavLink key={index} to={item.path} className="nav_link nav-element text-decoration-none" onClick={onNavLinkClick}>
                                                    {/* <span>{item?.icon === null ? <img src={item?.svg_icon} className="bg-white" style={{ width: '20px', height: "20px" }} /> : <i className={item.icon}></i>}</span> */}
                                                    {item.icon !== null ? (<span><i className={item.icon}></i></span>) : (
                                                        <span><img src={item.svg_icon} alt="Nav Image" className="nav-icon" /></span>)}
                                                    <span className="nav_name">{item.name}</span>
                                                </NavLink>
                                            ))}
                                            <div className='shadow list-unstyled fixed-bottom w-75 d-flex justify-content-center ms-3'>
                                                <li className='cursor-pointer rounded-5 fw-bold fs-4 list-style-type-none primary-bg text-white px-5 pb-1 mb-2' onClick={onClickLogout}><span className=''><i className="bi bi-box-arrow-right fs-1 fw-bold pe-2" ></i></span>Logout</li>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="me-md-auto ms-md-3 d-none d-md-block">
                        </div>
                        <div className="d-none d-md-flex align-items-center">
                            <div className='d-flex justify-content-end'>
                                <div className='me-2 text-dark my-3'>{username}</div>
                                <div className="dropdown px-0">
                                    <button className="btn btn-secondary bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className='bi bi-person-circle text-dark fs-3 me-1'></i> <span style={{ fontSize: "13px", color: "black" }}> </span>  <span><i className="bi bi-chevron-down" style={{ color: "black" }}></i> </span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-position">
                                        {/* <li><span className="dropdown-item cursor-pointer text-dark "><i className="bi bi-person" ></i><span className='ms-2'>{username}</span></span></li> */}
                                        <li className='dropdown-item cursor-pointer' onClick={onClickLogout}><span className='me-2'><i className="bi bi-box-arrow-right" ></i></span>Logout</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className={nav} id="nav-bar">
                        <nav className="navinstitute">
                            <div>
                                <h2 className='nav_logo text-primary mx-auto'>
                                    <img src={HeaderLogo} alt="" className='header-logo' />
                                </h2>
                                <div className="nav_list pb-5">
                                    {/* {navItems.map((item: any, index: number) => (
                                        <NavLink key={index} to={item.path} className="nav_link nav-element text-decoration-none">
                                            {item.icon !== null ? (<span><i className={item.icon}></i></span>) : (
                                                <span><img src={item.svg_icon} alt="Nav Image" className="nav-icon" /></span>)}
                                            <span className="nav_name">{item.name}</span>
                                        </NavLink>
                                    ))} */}
                                    {navItems.map((item: any, index: number) => (
                                        // Inside the render function or JSX section
                                        // Inside the render function or JSX section
                                        <div key={index}
                                            className={`nav_element ${activeMenu === index ? "collapsed" : ""
                                                }`}
                                        >
                                            <div onClick={() => handleMenuClick(index)}>
                                                <NavLink
                                                    key={index}
                                                    to={item.path}
                                                    className="nav_link nav-element text-decoration-none"
                                                >
                                                    <div className="d-flex align-items">
                                                        <i className={item.icon}></i>
                                                        <div className="nav_name ms-2 word-break">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                    {item.submenu && (
                                                        <div className={`arrow-icon ms-auto ${activeMenu === index ? "collapsed" : ""}`}>
                                                            <div className="bi bi-chevron-right"></div>
                                                        </div>
                                                    )}
                                                </NavLink>
                                                <span></span>
                                                {/* <span className="nav_name">{item.name}</span> */}
                                            </div>
                                            {item.submenu && (
                                                <div
                                                    className={`nav_sublist ${activeMenu === index ? "collapsed" : ""
                                                        }`}
                                                >
                                                    {item.submenu.map(
                                                        (subitem: any, subindex: number) => (
                                                            <NavLink
                                                                key={subindex}
                                                                to={subitem.path}
                                                                className="nav_sublink nav-element text-decoration-none"
                                                            >
                                                                <span className="nav_name ms-4">{subitem.name}</span>
                                                            </NavLink>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </nav>
                    </div>
                    {/* {main content} */}
                    <div className='content-bg-image'>
                        <div className="content-body ">
                            <div className="container-fluid">
                                <div className="main-title ">
                                    <DashboardRoutes />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </div>
    )
}
