import React, { useEffect, useState } from "react";
import { Search } from "../../../components/search/search";
import { DataTable } from "../../../components/data-table";
import { PreviligesDataGridCols } from "./data-grid-cols";
import { AddPrevilige } from "./add-previlige";
import { LookupService } from "../../../app/core/lookup.service";
import toast from "react-hot-toast";

export const Prevliges = () => {
  const [loading, setLoading] = useState(false);
  const [previligesData, setPreviligesData] = useState<any>([]);
  const [canShowForm, setCanShowForm] = useState(false);

  useEffect(() => {
    loadFeatureGroups();
  }, []);

  const loadFeatureGroups = async () => {
    try {
      const featureGroups = await LookupService.getPrevilige();
      setPreviligesData(featureGroups.data)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid content-bg-color">
      {canShowForm && (
        <AddPrevilige onClose={() => setCanShowForm(false)}></AddPrevilige>
      )}
      {!canShowForm && (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-end d-flex justify-content-between pl-8">
                <h4 className="primary-color fw-bold mt-3">Business Units</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 my-3">
                <Search customClass={true} />
              </div>
              <div className="col-md-3 my-3">
                <button className="btn btn-primary">Search</button>&nbsp;
                <button className="btn btn-secondary">Clear</button>
              </div>
              <div className="col-md-5 my-3 text-end">
                <button
                  className="btn btn-primary"
                  onClick={() => setCanShowForm(true)}
                >
                  Add
                </button>
                &nbsp;
              </div>
            </div>
            <div className="row ">
              <div className="col-12 px-0">
                {loading && (
                  <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
                {!loading && (
                  <>
                    <DataTable
                      tableData={previligesData}
                      TableCols={PreviligesDataGridCols}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
