import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ApisService } from '../app/core/apis.service';
import { KeyConversion, ProductHeadings } from './../app/utility/product-xls-keys';
import { ProdcutUpload } from '../app/model/product-upload';
import { dataDecrypt, encrypt } from '../app/utility/encryption-helper';
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
export const ProductBulkupload = () => {
  const routerParams = useParams();
  const [availableProducts, setAvailableProducts] = useState<ProdcutUpload[]>([]);
  const [buData, setBuData] = useState<any>(null);
  const [canshowToaster, setCanshowToaster] = useState(false);

  useEffect(() => {
    loadBUProducts();
    loadBuData();
    return () => {
    }
  }, []);

  const loadBuData = async () => {
    console.log('loadBuData ');

    const apiRes = await ApisService.getOrganizationData('findById', routerParams.bu as string);
    console.log('loadBuData ', apiRes);
    try {
      const orgRes = JSON.parse(dataDecrypt(apiRes.data.data));
      console.log('orgRes ', orgRes);
      setBuData(orgRes[0]);
    } catch (error) {
      console.log('error ', error);

    }
  }
  const loadBUProducts = async () => {
    const apiRes = await ApisService.productApi('findProductList_v2', routerParams.bu as string);
    try {
      const productsRes = JSON.parse(dataDecrypt(apiRes.data.data));
      if (productsRes.results) {
        setAvailableProducts(productsRes.results.map((el: any) => filterKeysForForm(el)));
      } else {
        onAddNewProduct();
      }
    } catch (error) {

    }
  }

  const onAddNewProduct = () => {
    const previousProducts = [...availableProducts];
    previousProducts.push({
      id: '',
      bu_id: '',
      name: '',
      brand: '',
      description: '',
      product_sector: '',
      product_segment: '',
      product_category: '',
      product_sub_category: '',
      in_stock: true,
      for_sale: true,
      quantity: 1,
      quantity_in: 'unit',
      batch_number: '',
      batch_price: '',
      batch_net_rate: '',
      batch_mrp: '',
      batch_currency_code: '',
      manufacture_date_time: '',
      expiry_date: '',
      manufacture_mobile: '',
      manufacture_name: '',
      manufacture_plot_no: '',
      manufacture_building: '',
      manufacture_street: '',
      manufacture_country: '',
      manufacture_state: '',
      manufacture_city: '',
      manufacture_pin_code: '',
      packing_height: '',
      packing_width: '',
      packing_depth: '',
      packing_volume: '',
      packing_weight: '',
      tax_type: '',
      tax_hsn_code: '',
      tax_rate: '',
      images: [],
      productStatus: ''
    });
    setAvailableProducts(previousProducts);
  }

  const filterKeysForForm = (productObj: any): any => {
    const filteredObj: any = { ...productObj };
    try {

      for (const key in KeyConversion) {
        if (Object.prototype.hasOwnProperty.call(KeyConversion, key)) {
          const element = KeyConversion[key];
          if (element.type === 'array') {
            for (let index = 0; index < element.keys.length; index++) {
              const innerElement: any = productObj[key][0];
              const innerKey = element.keys[index];
              if (innerElement[innerKey.dbKey] || innerElement[innerKey.dbKey] === 0) {
                filteredObj[innerKey.key] = innerElement[innerKey.dbKey];
              }
            }
          } else if (element.type === 'obj') {
            // const preparedElement: any = {};
            for (let index = 0; index < element.keys.length; index++) {
              const innerElement: any = productObj[key];
              const innerKey = element.keys[index];
              if (innerElement[innerKey.dbKey]) {
                filteredObj[innerKey.key] = innerElement[innerKey.dbKey];
                if (innerKey.postfix && filteredObj[innerKey.key]) {
                  filteredObj[innerKey.key] = filteredObj[innerKey.key].split(innerKey.postfix)[0];
                }
              }
            }

          }
        }
      }
    } catch (error) {
      console.log('error ', error);

    }

    return filteredObj;
  }

  const getFilteredValue = (value: any, key: string): any => {
    const productHeading = ProductHeadings.find(el => el.control === key);
    let retVal = value;
    if (productHeading) {
      switch (productHeading.type) {
        case 'number':
          retVal = Number(value);
          break;
      }
    }
    return retVal;
  }
  const filterKeys = (productObj: any): any => {
    const filteredObj: any = { ...productObj };
    for (const key in KeyConversion) {
      if (Object.prototype.hasOwnProperty.call(KeyConversion, key)) {
        const element = KeyConversion[key];
        if (element.type === 'array') {
          const preparedElement = [{}];
          for (let index = 0; index < element.keys.length; index++) {
            const innerElement: any = preparedElement[0];
            const innerKey = element.keys[index];
            if (productObj[innerKey.key]) {
              const val = getFilteredValue(productObj[innerKey.key], innerKey.key);
              innerElement[innerKey.dbKey] = val;
            }
          }
          filteredObj[key] = preparedElement;
        } else if (element.type === 'obj') {
          const preparedElement: any = {};
          for (let index = 0; index < element.keys.length; index++) {
            const innerKey = element.keys[index];
            if (productObj[innerKey.key]) {
              const val = getFilteredValue(productObj[innerKey.key], innerKey.key);
              preparedElement[innerKey.dbKey] = val;
              if (innerKey.postfix && preparedElement[innerKey.dbKey]) {
                preparedElement[innerKey.dbKey] += innerKey.postfix;
              }
            }
          }
          filteredObj[key] = preparedElement;

        }
        // if(KeyConversion[key])
      }
    }
    return filteredObj;
  }

  const onSubmit = async () => {
    console.log('available products', availableProducts);
    const validProducts = getPreparedProductData(availableProducts);
    console.log(validProducts, validProducts.filter(el => el.productStatus && el.productStatus));
    const filterData = validProducts.filter(el => el.productStatus && el.productStatus);
    console.log(filterData, "filterData");

    setAvailableProducts(validProducts);
    if (filterData.length > 0) {
      toast.error("Please check all values once");
    } else {
      for (let index = 0; index < availableProducts.length; index++) {
        const element = availableProducts[index];
        const filteredElement = filterKeys(element);
        if (filteredElement._id) {
          const productData = JSON.stringify(filteredElement);
          await ApisService.productApi('updateWithStr', productData);
        } else {
          const productData = JSON.stringify({ ...filteredElement, offers: [], bu_id: buData.id, raw_material: false });
          await ApisService.productApi('createWithStr', productData);
        }
      }
      setCanshowToaster(true);
    }


  }


  const getPreparedProductData = (availableProducts: any) => {
    const preparedData = [];

    for (let i = 0; i < availableProducts.length; i++) {
      const element = availableProducts[i];
      const filteredElement = filterKeys(element);
      if (filteredElement.name || filteredElement.name === '') {
        const name = isValidProductName(filteredElement.name);
        if (!name) {
          if (filteredElement.name === '') {
            filteredElement.productStatus = `Please Enter name, Don't Empty`;
          }
        }
      }

      if (filteredElement.quantity || filteredElement.quantity === '') {
        const quantity = isValidNumberPattern(filteredElement.quantity);

        if (!quantity) {
          if (filteredElement.quantity < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.batch_mrp || filteredElement.batch_mrp === '') {
        const batch_mrp = isValidNumberPattern(filteredElement.batch_mrp);

        if (!batch_mrp) {
          if (filteredElement.batch_mrp < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.batch_net_rate || filteredElement.batch_net_rate === '') {
        const batch_net_rate = isValidNumberPattern(filteredElement.batch_net_rate);

        if (!batch_net_rate) {
          if (filteredElement.batch_net_rate < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.batch_price || filteredElement.batch_price === '') {
        const batch_price = isValidNumberPattern(filteredElement.batch_price);

        if (!batch_price) {
          if (filteredElement.batch_price < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.tax_rate || filteredElement.tax_rate === '') {
        const tax_rate = isValidNumberPattern(filteredElement.tax_rate);

        if (!tax_rate) {
          if (filteredElement.tax_rate < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.packing_height || filteredElement.packing_height === '') {
        const packing_height = isValidNumberPattern(filteredElement.packing_height);

        if (!packing_height) {
          if (filteredElement.packing_height < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.packing_width || filteredElement.packing_width === '') {
        const packing_width = isValidNumberPattern(filteredElement.packing_width);

        if (!packing_width) {
          if (filteredElement.packing_width < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.packing_depth || filteredElement.packing_depth === '') {
        const packing_depth = isValidNumberPattern(filteredElement.packing_depth);

        if (!packing_depth) {
          if (filteredElement.packing_depth < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.packing_volume || filteredElement.packing_volume === '') {
        const packing_volume = isValidNumberPattern(filteredElement.packing_volume);

        if (!packing_volume) {
          if (filteredElement.packing_volume < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      if (filteredElement.packing_weight || filteredElement.packing_weight === '') {
        const packing_weight = isValidNumberPattern(filteredElement.packing_weight);

        if (!packing_weight) {
          if (filteredElement.packing_weight < 0) {
            filteredElement.productStatus = `Please Enter positive value`;
          }
        }
      }

      preparedData.push(filteredElement);
    }

    return preparedData;

  }

  const isValidProductName = (data: any) => {
    if (data) {
      return true;
    } else {
      return false;
    }
  }

  const isValidNumberPattern = (data: any) => {
    if (data >= 0) {
      return true;
    } else {
      return false;
    }
  }


  const onChangeData = (event: any, el: any, productEl: any) => {
    const value = event.target.value;
    // console.log('value ', value, el, productEl);
    el[productEl.control] = value;

    // setAvailableProducts([...availableProducts]);
  }

  const getDefaultValue = (el: any, key: string) => {
    return el[key];
  }

  const handleFileUpload = (e: any) => {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e: any) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const loadedData = XLSX.utils.sheet_to_json(ws);
      console.log(loadedData);
      uploadExcelDataToForm(loadedData);
      // const filterData: any = dataParse.filter((item: any) => item["Product Name"]);
      // console.log(filterData);
      // setAvailableProducts(filterData);

    };
    reader.readAsBinaryString(f)
  };

  const uploadExcelDataToForm = (jsonData: any): void => {
    console.log('jsonData ', jsonData);

    const previousProducts = [...availableProducts];
    for (let index = 0; index < jsonData.length; index++) {
      const formGroupData: any = {};
      const element = jsonData[index];
      // for (let i = 0; i < element.length; i++) {
      for (var key in element) {

        const productKeyValue = element[key];
        // console.log('key ', key, productKeyValue, element);
        const productColumn = ProductHeadings.find(el => el.heading === key);
        if (productColumn) {
          formGroupData[productColumn.control] = productKeyValue;
        }
      }
      console.log('form group ', formGroupData);

      previousProducts.push(formGroupData);
      // this.products.at(this.products.length - 1).patchValue(formGroupData);
    }
    setAvailableProducts(previousProducts);
    console.log('previous ', previousProducts);

  }

  const handleProductImageUpload = (e: any) => {
    console.log(e);
  }

  const getDisplayError = (el: any, productEl: any, index: number) => {
    console.log(el, productEl, index, el[productEl.control]);

    if (productEl.heading === 'Product Name' || productEl.heading === 'Quantity' || productEl.heading === 'Distributor Price' || productEl.heading === 'Net Rate (Our Price)' ||
      productEl.heading === 'MRP' || productEl.heading === 'Item Height (meter)' || productEl.heading === 'Item Width (meter)' || productEl.heading === 'Item Depth (meter)' ||
      productEl.heading === 'Item Volume(ltr)' || productEl.heading === 'Item Weight(grams)' || productEl.heading === 'Tax Rate (%)') {

      if (el.name === "") {
        return el.productStatus = 'product name is empty';
      } else {
        el[productEl.control] = el.name;
        el.productStatus = "";
      }

      if (el.quantity < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.quantity;
        el.productStatus = "";
      }

      if (el.batch_mrp < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.batch_mrp;
        el.productStatus = "";
      }

      if (el.batch_net_rate < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.batch_net_rate;
        el.productStatus = "";
      }

      if (el.batch_price < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.batch_price;
        el.productStatus = "";
      }

      if (el.tax_rate < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.tax_rate;
        el.productStatus = "";
      }

      if (el.packing_height < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.packing_height;
        el.productStatus = "";
      }

      if (el.packing_width < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.packing_width;
        el.productStatus = "";
      }

      if (el.packing_depth < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.packing_depth;
        el.productStatus = "";
      }

      if (el.packing_volume < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.packing_volume;
        el.productStatus = "";
      }

      if (el.packing_weight < 0) {
        return el.productStatus = 'Please Enter positive value';
      } else {
        el[productEl.control] = el.packing_weight;
        el.productStatus = "";
      }

    }
  }

  return (
    <div>
      <div className={`toast align-items-center text-white bg-primary position-fixed ${canshowToaster ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true" style={{ left: '25%', top: '30%', width: '50%', height: '30%' }}>
        <div className="toast-header">
          {/* <img src="..." className="rounded me-2" alt="..."> */}
          <strong className="me-auto">Success</strong>
          {/* <small>11 mins ago</small> */}
          <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div className="toast-body h-100 d-flex align-items-center justify-content-center">
          <h4>Products updated sucessfully</h4>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-primary position-fixed top-0 w-100">
        <div className="container text-white">
          <div>
            <h3>Vyapar</h3>
            <p className="m-0">Business: <b style={{ color: 'yellow' }} >{buData?.business_name}</b></p>
          </div>
          <div>
            <input type="file" onChange={handleFileUpload} style={{ display: 'none' }} id="fileUploadInput" accept=".xlsx" />
            <label
              htmlFor="fileUploadInput"
              className="btn btn-link text-white m-3">
              Upload Excel
            </label>
            {/* <button className="btn btn-link text-white m-3">Download Excel</button> */}
          </div>
          {/* <div>
            <button className="btn btn-link text-white m-3">Go To Products</button>
          </div > */}

        </div >
      </nav >
      <div style={{ marginTop: '100px', marginBottom: '100px' }} className='p-3'>
        <table className='product-table'>
          <tr>
            {ProductHeadings.map(el => <>
              <th>{el.heading}</th>
            </>)}
            <th>Product Image</th>
          </tr>
          {availableProducts.map((el, index) => <>
            <tr key={index} className='product-table-row'>
              {ProductHeadings.map(productEl => <>
                <td style={{ minWidth: '250px' }}>
                  {productEl.type === 'text' && <input onChange={(event) => onChangeData(event, el, productEl)} className='form-control' defaultValue={getDefaultValue(el, productEl.control)} />}
                  {productEl.type === 'number' && <input onChange={(event) => onChangeData(event, el, productEl)} className='form-control' type='number' defaultValue={getDefaultValue(el, productEl.control)} />}
                  {productEl.type === 'date' && <input onChange={(event) => onChangeData(event, el, productEl)} className='form-control' type='date' defaultValue={getDefaultValue(el, productEl.control)} />}
                  {productEl.type === 'select' && <select onChange={(event) => onChangeData(event, el, productEl)} className='form-control' defaultValue={getDefaultValue(el, productEl.control)}>
                    {productEl.options?.map((optionsEl: any) => <option>{optionsEl.label}</option>)}
                  </select>}
                  {el.productStatus && <span style={{ color: 'red' }}>{getDisplayError(el, productEl, index)}</span>}
                </td>
              </>)}
              <td style={{ minWidth: '200px' }}>
                <input type="file" onChange={handleProductImageUpload} style={{ display: 'none' }} id="fileProductImage" />
                <label
                  htmlFor="fileProductImage"
                  className="" style={{ padding: '0px 25px' }}>
                  <i className="bi bi-upload  cursor-pointer edit-icon p-2" style={{ fontSize: '20px' }}></i>
                </label>
              </td>
            </tr>
          </>)}
        </table>
      </div>
      <div className="text-center w-100 position-fixed" style={{ backgroundColor: '#8282825c', bottom: 0, left: 0 }}>
        <button className="btn btn-primary m-3" onClick={onAddNewProduct}>Add New</button>
        {/* <button *ngIf="products.length>0" class="btn btn-primary m-3" (click)="onSubmit()">Submit</button> */}
        <button className="btn btn-primary m-3" onClick={onSubmit}>Submit</button>
      </div>
    </div >
  )
}
