import React, { SyntheticEvent, useEffect, useState } from 'react'
import { MenuSystemItem } from '../../../app/model/menu-system-item';
import { ApisService } from '../../../app/core/apis.service';
import { dataDecrypt, encrypt } from '../../../app/utility/encryption-helper';
import { MenuSystemUpdates } from '../../../app/core/menu-system-updates';
import { MenuSystemUpdateItem } from '../../../app/model/menu-system-update-item';
import { MenuSystemItemView } from './menu-system-item-view';

interface Props {
    type: 'system' | 'bu';
    bu?: string;
}
export const MenuSystem = (props: Props) => {
    const [menuSystemData, setMenuSystemData] = useState<MenuSystemItem[]>([]);
    const [buFeatures, setBuFeatures] = useState<string[]>([]);
    const [canShowScuccess, setCanShowScuccess] = useState(false);
    const [totalKeys, setTotalKeys] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        loadMenuSystem();
    }, []);

    useEffect(() => {
        if (menuSystemData && menuSystemData.length) {
            if(props.type === 'system' ) {
                prepareMenuSystemUpdate();
                setIsLoaded(true);
            }
        }
    }, [menuSystemData]);

    useEffect(() => {
        if (menuSystemData && menuSystemData.length) {
            prepareMenuSystemUpdate();
            setIsLoaded(true);
        }
    }, [buFeatures]);

    const loadMenuSystem = async () => {
        const res = await ApisService.getOrganizationData('getOrgMenuSystem', '');
        if (res.data && res.data.data) {
            const menuData = JSON.parse(dataDecrypt(res.data.data));
            setMenuSystemData(menuData.menus);
            if (props.bu) {
                try {
                    const buRes = await ApisService.getOrganizationData('findById', encrypt(JSON.stringify({ id: props.bu })));
                    const buResData = JSON.parse(dataDecrypt(buRes.data.data));
                    setBuFeatures(buResData[0].features);
                } catch (error) {
                    console.error('Error while loading BU Features', error);

                }
            }
        }
    }
    const onChangeVisibility = (event: SyntheticEvent, item: MenuSystemItem) => {
        const inputEl = event.target as HTMLInputElement;
        if (inputEl) {
            console.log('MenuSystemUpdates.menuSystemItems', MenuSystemUpdates.menuSystemItems);
            
            const detectedItem = MenuSystemUpdates.menuSystemItems.find(el => el.code === item.code);
            if (detectedItem) {
                detectedItem.enabled = inputEl.checked;
            }
        }
    }

    const prepareMenuSystemUpdate = () => {
        setTotalKeys(0);
        const preparedMenuSystem: MenuSystemUpdateItem[] = [];
        for (let index = 0; index < menuSystemData.length; index++) {
            const element = menuSystemData[index];
            
            if (props.type === 'system') {
                preparedMenuSystem.push({
                    code: element.code,
                    enabled: element.enabled
                });
            } else if (props.type === 'bu') {
                const isEnabled = buFeatures?.find(el => el === element.code);
                preparedMenuSystem.push({
                    code: element.code,
                    enabled: isEnabled ? true : false
                });
            }
            if (element.children) {
                prepareInnerMenuItem(element.children, preparedMenuSystem);
            }
            setTotalKeys((prev) => prev + 1);
        }
        MenuSystemUpdates.menuSystemItems = preparedMenuSystem;
        console.log('prepared ', MenuSystemUpdates.menuSystemItems);

    }

    const prepareInnerMenuItem = (menuItems: MenuSystemItem[], preparedMenuSystem: MenuSystemUpdateItem[]) => {
        for (let index = 0; index < menuItems.length; index++) {
            const element = menuItems[index];
            if (props.type === 'system') {
                preparedMenuSystem.push({
                    code: element.code,
                    enabled: element.enabled
                });
            } else if (props.type === 'bu') {
                const isEnabled = buFeatures?.find(el => el === element.code);
                console.log('is enabled .....', isEnabled, element.code);
                
                preparedMenuSystem.push({
                    code: element.code,
                    enabled: isEnabled ? true : false
                });
            }
            if (element.children) {
                prepareInnerMenuItem(element.children, preparedMenuSystem);
            }
            setTotalKeys((prev) => prev + 1);
        }
        
    }

    const onSaveSystem = async () => {
        if (props.type === 'system') {
            const menuData = encrypt(JSON.stringify(MenuSystemUpdates.menuSystemItems));
            await ApisService.getOrganizationData('updateOrgMenuSystem', menuData);
        } else if (props.type === 'bu') {
            const orgFeatureReq = { bu: props.bu, features: MenuSystemUpdates.menuSystemItems.filter(el => el.enabled).map(el => el.code) };
            const menuData = encrypt(JSON.stringify(orgFeatureReq));
            console.log('org feature data ', orgFeatureReq, props);

            await ApisService.getOrganizationData('updateOrgFeatures', menuData);
        }
        setCanShowScuccess(true);
    }

    const mergeFeatures = () => {
        console.log('mergeFeatures');

    }

    return (
        <div className='p-5 content-bg-color'>
            <b>Total Keys:</b> {totalKeys}
            {
                menuSystemData?.map((el, dIndex) => <>
                    {(el.enabled || props.type === 'system') && <div className='ms-3' key={el.code}>
                        <input type="checkbox" name="" id="" className='me-3' onClick={(event) => onChangeVisibility(event, el)} defaultChecked={el.enabled} />
                        <span>{el.label}</span>
                        {
                            el.children && el.children.map(childEl => <MenuSystemItemView type={props.type} key={childEl.code} item={childEl}></MenuSystemItemView>)
                        }
                    </div>}
                </>)
            }
            {canShowScuccess && <div className='text-success text-center'>Saved successfully</div>}
            <div className='text-end'>
                <button className="btn btn-primary" onClick={onSaveSystem}>Save</button>
            </div>
        </div>
    )
}
